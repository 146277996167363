import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInputComponent } from './app-input/app-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from '../app-material.module';
import { AppPageTitleComponent } from './app-page-title/app-page-title.component';
import { AppSelectComponent } from './app-select/app-select.component';
import { UserTableComponent } from './tables/user-table/user-table.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { AppDonutChartComponent } from './charts/app-donut-chart/app-donut-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { AppLineChartComponent } from './charts/app-line-chart/app-line-chart.component';
import { RoleTableComponent } from './tables/role-table/role-table.component';
import { AppAvatarComponent } from './app-avatar/app-avatar.component';
import { AppSnackbarComponent } from './app-snackbar/app-snackbar.component';
import { AppActionBarComponent } from './app-action-bar/app-action-bar.component';
import { AppAutocompleteComponent } from './app-autocomplete/app-autocomplete.component';
import { UploadFileDialogComponent } from './dialogs/upload-file-dialog/upload-file-dialog.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppMultiDonutChartComponent } from './charts/app-multi-donut-chart/app-multi-donut-chart.component';
import { AppBarChartComponent } from './charts/app-bar-chart/app-bar-chart.component';
import { MonthYearFormatDirective } from '../month-year-format.directive';


@NgModule({
  declarations: [
    AppInputComponent,
    AppSelectComponent,
    AppAutocompleteComponent,
    AppAvatarComponent,
    UserTableComponent,
    RoleTableComponent,
    AppPageTitleComponent,
    AppActionBarComponent,
    ConfirmDialogComponent,
    UploadFileDialogComponent,
    AppDonutChartComponent,
    AppMultiDonutChartComponent,
    AppLineChartComponent,
    AppSnackbarComponent,
    AppBarChartComponent,
    MonthYearFormatDirective
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    NgChartsModule,
    TranslateModule,
    NgxFileDropModule,
    PdfViewerModule
  ],
  exports: [
    AppInputComponent,
    AppSelectComponent,
    AppAutocompleteComponent,
    AppAvatarComponent,
    UserTableComponent,
    RoleTableComponent,
    ConfirmDialogComponent,
    UploadFileDialogComponent,
    AppPageTitleComponent,
    AppActionBarComponent,
    AppDonutChartComponent,
    AppMultiDonutChartComponent,
    AppLineChartComponent,
    AppSnackbarComponent,
    AppBarChartComponent
  ]
})
export class CoreComponentsModule { }
