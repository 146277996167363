import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppConfigService } from './services/app.config.service';
import { AuthService } from './services/auth.service';
@Injectable()
export class MobileGuard {
  constructor(private appConfigService: AppConfigService, public router: Router, public authService: AuthService) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkIfMobile();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkIfMobile();
  }

  checkIfMobile() {
    try {
      if (this.appConfigService.isAndroid || this.appConfigService.isIos) {
        this.authService.CurrentUser = null;
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['downloadapp']);
        return false;
      }
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
