import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppSettings } from 'src/app/models/AppSettings';
import { UtilService } from './util.service';
import { DateAdapter } from '@angular/material/core';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from '../models/apiResponse';

@Injectable()
export class AppConfigService {
  isAndroid = false;
  isIos = false;
  appSettings = new AppSettings();
  environment = environment;

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private dateAdapter: DateAdapter<any>,
  ) { }

  async appInit() {
    await this.initAppSettings();
    this.initAppLanguage();
    this.checkUserAgent();

  }

  async initAppSettings() {
    const response = await lastValueFrom(this.http.get<ApiResponse>(`${this.environment.apiUrl}` + '/v1/init-settings'));
    if (response.performed) {
      for (let setting of response.dto) {
        this.appSettings[setting.configkey] = setting.configvalue;
        //Setting whitelabel variables
        if (setting.appid === 'theme') {
          document.documentElement.style.setProperty('--' + setting.configkey, setting.configvalue);
        }
      }
      if (this.appSettings.appname) {
        document.title = this.appSettings.appname;
      }
      if (this.appSettings.logoSmall) {
        document.getElementById('favicon').setAttribute('href', this.appSettings.logoSmall);
      }
      if (this.appSettings.hoverPrimaryColor) {
        this.utilService.setSpinnerColor(this.appSettings.hoverPrimaryColor);
      }
      await this.setGoogleApiScript();
    }
  }

  initAppLanguage() { 
    this.utilService.translate.addLangs(['en', 'it']);
    this.utilService.translate.setDefaultLang('it');
    const browserLang = this.utilService.translate.getBrowserLang();
    this.utilService.translate.use(browserLang?.match(/en|it/) ? browserLang : 'it');
    this.dateAdapter.setLocale(browserLang);
  }

  setAppLanguage(language: string) {
    this.utilService.translate.use(language?.match(/en|it/) ? language : 'it');
    this.dateAdapter.setLocale(language);
  }

  setGoogleApiScript() {
    const googleScriptSrc = 'https://maps.googleapis.com/maps/api/js?key=' + this.appSettings.google_api_key + '&loading=async&libraries=places&language=it'
    var script = document.createElement("script");
    script.defer = true;
    script.type = 'text/javascript';
    script.src = googleScriptSrc;
    document.head.appendChild(script);
  }

  checkUserAgent() {
    var userAgent = window.navigator.userAgent,
    os = null;
    if (/iPad/.test(userAgent) || (/Android/.test(userAgent) && !(/Mobile/.test(userAgent)))) {
      os = 'tablet'
    } else if (/iPhone|iPod/.test(userAgent)) {
      os = 'iOS';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    }
    if (os === 'iOS') {
      this.isAndroid = false;
      this.isIos = true;
    } else if (os === 'Android') {
      this.isIos = false;
      this.isAndroid = true;
    }
  }
}
