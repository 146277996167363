import { DatePipe } from '@angular/common';
import { Component, Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import * as moment from 'moment';
import { Moment } from 'moment';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrls: ['./app-input.component.scss']
})
export class AppInputComponent implements OnChanges {
  hidePassword = true;
  @Input() label = 'label';
  @Input() placeholder = '';
  @Input() hintStart!: string;
  @Input() hintEnd!: string;
  @Input() required = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() type = 'text';
  @Input() minLength = '0';
  @Input() maxLength = '255';
  @Input() appearance = 'outline' as MatFormFieldAppearance;
  @Input() property: any;
  @Input() matSuffix = '';
  @Input() maxDate = '';
  @Input() disabledSearch = false;
  @Input() hideSearch = false;
  @Input() small = false;
  @Input() disablePasswordControl = false;
  @Output() propertyChange = new EventEmitter<any>();
  @Output() controlValidity = new EventEmitter<any>();
  @Output() searchClick = new EventEmitter<any>();
  @Output() clearClick = new EventEmitter<any>();
  @Output() dateChange = new EventEmitter<any>();
  @Output() inputChange = new EventEmitter<any>();
  formControl = new FormControl('', []);
  validators: ValidatorFn[] = [];

  constructor(
    public datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }
    if (this.type === 'email') {
      this.validators.push(Validators.pattern('^([\\w\\-\\.]+)@((\\[([0-9]{1,3}\\.){3}[0-9]{1,3}\\])|(([\\w\\-]+\\.)+)([a-zA-Z]{2,4}))$'));
    } else if (this.type === 'number') {
      this.validators.push(Validators.pattern('^[0-9]*$'));
    } else if (this.type === 'decimal') {
      this.validators.push(Validators.pattern('^\\d+(\\.\\d{1,2})?$'));
    } else if (this.type === 'password') {
      if (this.disablePasswordControl) {
        this.validators.push(Validators.minLength(5), Validators.maxLength(30));
      } else {
        this.validators.push(Validators.minLength(8), Validators.maxLength(32), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,32}$'));
      }
    }
    if (this.type === 'date' && this.property) {
      const date = new Date(this.property);
      this.property = this.datepipe.transform(date, 'yyyy-MM-dd');
    }
    this.formControl = new FormControl({ value: '', disabled: this.disabled }, this.validators);
    // this.formControl.markAsTouched();
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes['property']) {
      if (this.type === 'date' && this.property) {
        const date = new Date(this.property);
        this.property = this.datepipe.transform(date, 'yyyy-MM-dd');
      }
    }
  }

  changeModel(event: any) {
    if (event instanceof Date) {
      event = this.datepipe.transform(event, 'yyyy-MM-dd');
    }
    this.propertyChange.emit(event);
    this.controlValidity.emit(this.formControl.errors ? false : true);
  }

  changeInput(event: any) {
    this.inputChange.emit(event);
  }

  onSearchClick() {
    this.searchClick.emit();
  }

  onClearField() {
    this.property = '';
    this.clearClick.emit();
  }

  onDateChange(event: any) {
    if (event && event.value) {
      this.dateChange.emit(event.value);
    }
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    ctrlValue.date(normalizedMonthAndYear.date());
    this.property = this.datepipe.transform(ctrlValue.toDate(), 'yyyy-MM-dd');
    datepicker.close();
  }

  validateNumberInput($evt) {
    const theEvent = $evt;
    let key = null;
    // Handle paste
    if (theEvent.type === 'paste') {
      key = $evt.getData('text/plain');
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

  validateDecimalInput($evt) {
    const theEvent = $evt;
    let key = null;
    // Handle paste
    if (theEvent.type === 'paste') {
      key = $evt.getData('text/plain');
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    const regex = /[0-9.]/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) {
        theEvent.preventDefault();
      }
    }
  }

}
