import { Component, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app.config.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  email: string = '';
  confirmPassword: string = '';
  name: string = '';
  surname: string = '';
  passwordRecovery = false;
  message: string = '';
  @ViewChildren('formField')
  formFields!: QueryList<any>;
  formValid = false;
  token = null;
  savedLinkRedirect = localStorage.getItem('redirectLink')
    ? localStorage.getItem('redirectLink')
    : false;

  constructor(
    public appConfigService: AppConfigService,
    private utilService: UtilService,
    public router: Router,
    private dataService: DataService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.token = this.route.snapshot.queryParamMap.get('token');
  }

  ngOnInit() {
    if (this.router.url !== '/login' && !this.token) {
      this.router.navigate(['login']);
    }
  }

  ngAfterViewInit() {
    // this.checkFormValidation();
  }

  togglePasswordRecovery() {
    this.passwordRecovery = !this.passwordRecovery;
  }

  checkFormValidation() {
    this.formValid = this.utilService.validateForm(this.formFields);
  }

  async submit() {
    if (this.formValid) {
      this.utilService.showSpinner();
      if (this.router.url === '/login') {
        if (!this.passwordRecovery) {
          const loggedIn = await this.dataService.login(this.username,this.password);
          if (loggedIn) {
            if ((this.authService.isRole('Fornitore') || this.authService.isRole('Affiliato')) && this.authService.CurrentUser.anagraficaId ) {
              const anagrafica = await this.dataService.getAnagrafica(this.authService.CurrentUser.anagraficaId);
              if (anagrafica) {
                const userUpdated = Object.assign({}, this.authService.CurrentUser);
                userUpdated.ragioneSociale = anagrafica.denominazione;
                if (anagrafica.servizio) {
                  userUpdated.servizio = anagrafica.servizio;
                }
                if (anagrafica) {
                  userUpdated.anagrafica = anagrafica;
                }
                this.authService.CurrentUser = userUpdated;
              }
            }

            if (this.savedLinkRedirect) {
              localStorage.removeItem('redirectLink');
              this.router.navigate([this.savedLinkRedirect]);
            } else this.router.navigate(['dashboard']);
          }
        } else {
          const loggedIn = await this.dataService.forgotPassword(this.email);
          if (loggedIn) {
            this.router.navigate(['dashboard']);
          }
        }
      } else if (this.router.url.includes('/confirm-account?token=')) {
        const confirmed = await this.dataService.confirmAccount(
          this.token,
          this.email,
          this.password,
          this.confirmPassword
        );
        this.router.navigate(['login']);
      } else if (this.router.url.includes('/reset-password?token=')) {
        const confirmed = await this.dataService.resetPassword(
          this.token,
          this.email,
          this.password,
          this.confirmPassword
        );
        if (confirmed) {
          this.router.navigate(['login']);
        }
      } else if (this.router.url.includes('/supplier-registration?token=')) {
        const confirmed = await this.dataService.registerProvider(
          this.token,
          this.username,
          this.name,
          this.surname,
          this.name + ' ' + this.surname,
          this.password,
          this.confirmPassword
        );
        if (confirmed) {
          this.router.navigate(['login']);
        }
      } else if (this.router.url.includes('/affiliate-registration?token=')) {
        const confirmed = await this.dataService.registerAffiliate(
          this.token,
          this.username,
          this.name,
          this.surname,
          this.name + ' ' + this.surname,
          this.password,
          this.confirmPassword
        );
        if (confirmed) {
          this.router.navigate(['login']);
        }
      }
      this.utilService.hideSpinner();
    }
  }
}
