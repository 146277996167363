import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { UtilService } from 'src/app/services/util.service';
import { LineChartConfig } from 'src/app/models/ChartConfig';

Chart.register(ChartDataLabels);

@Component({
  selector: 'app-line-chart',
  templateUrl: './app-line-chart.component.html',
  styleUrls: ['./app-line-chart.component.scss'],
})
export class AppLineChartComponent implements OnInit, OnChanges {
  @Input() chartConfig = new LineChartConfig();
  @Input() chartHeight = 35;
  @Input() centerText = '';

  public chartData!: ChartData<'line'>;
  public chartOptions: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 14
          }
        }
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false
      },
      tooltip: {
        // bodyFont: {
        //   family: 'Nunito'
        // },
        // footerFont: {
        //   family: 'Nunito',
        // },
        // titleFont: {
        //   family: 'Nunito'
        // },
        boxPadding: 8,
        callbacks: {
          label: (tooltipItems) => {
            return tooltipItems.formattedValue + this.chartConfig.datalabelUnit;
          }
        }
      }
    },
    elements: {
      line: {
        tension: 0,
        borderWidth: 6,
      },
      point: {
        radius: 6,
        borderWidth: 4,
        hitRadius: 6,
        hoverRadius: 6,
        hoverBorderWidth: 3,
      }
    },
    scales: {
      x: {
        axis: 'x',
        grid: {
          display: true,
        },
        ticks: {
          font: {
            // family: 'Nunito',
            weight: 'bold'
          },
          color: 'black',
        },
      },
      y: {
        axis: 'y',
        grid: {
          display: true,
        },
        beginAtZero: true,
        max: this.chartConfig.max,
        ticks: {
          font: {
            // family: 'Nunito',
            weight: 'bold'
          },
          color: 'black',
          stepSize: this.chartConfig.stepSize,
          callback: (label, index, labels) => {
            return label + ' ' + this.chartConfig.datalabelUnit;
          }
        }
      },
    },
  };
  public chartType: ChartType = 'line';

  constructor(
    public utilService: UtilService
  ) {
  }

  ngOnInit() {
    if (this.chartConfig.chartColors.length <= 0) {
      this.chartConfig.chartColors = this.utilService.chartColors;
    }
    this.load();
  }

  load() {
    if (this.chartConfig.data && this.chartConfig.data.length > 0) {
      this.chartOptions!.scales!['y']!.max = this.chartConfig.max;
      this.chartOptions!.scales!['y']!.ticks!.autoSkipPadding = this.chartConfig.stepSize;
      let i = 0;
      this.chartData = {
        datasets: [],
      };
      this.chartConfig.data.forEach(d => {
        this.chartData.datasets.push(
          {
            data: d.values,
            label: d.label,
            backgroundColor: this.chartConfig.chartColors[i] + '30',
            borderColor: this.chartConfig.chartColors[i],
            pointBackgroundColor: this.chartConfig.chartColors[i],
            pointHoverBorderColor: this.chartConfig.chartColors[i],
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            fill: this.chartConfig.lineBackground ? 'origin' : 'none'
          }
        );
        i++;
      });
      this.chartData.labels = this.chartConfig.labels;
    }
  }

  registerCustomTextPlugin() {

    const customTextPlugin = {
      id: 'customTextCenter',
      beforeDraw: (chart: { ctx: any; width: any; height: any; }) => {
        const ctx = chart.ctx;
        const width = chart.width;
        const height = chart.height;
        const offset = 0;
        ctx.save();
        ctx.font = 'bold 34px "Inter", sans-serif';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('', width / 2, height / 2 + offset);
        ctx.restore();
      }
    };

    Chart.unregister(customTextPlugin);
    Chart.register({
      id: 'customTextCenter',
      afterDraw: (chart, args, options) => {
        if (this.centerText  || options['shouldDrawText']) {
          const ctx = chart.ctx;
          const width = chart.width;
          const height = chart.height;
          ctx.save();
          ctx.font = 'bold 34px "Inter", sans-serif';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(this.centerText, width / 2, height / 2);
          ctx.restore();
        }
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    this.load();
  }

}

