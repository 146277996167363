import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartOptions } from 'chart.js';
import { UtilService } from 'src/app/services/util.service';
import { BarChartConfig } from 'src/app/models/ChartConfig';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './app-bar-chart.component.html',
  styleUrls: ['./app-bar-chart.component.scss'],
})
export class AppBarChartComponent implements OnInit {

  @Input() chartConfig = new BarChartConfig;
  @Input() chartHeight = 22;
  @Input() type: string;

  constructor(
    public utilService: UtilService
  ) { }

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    scales: {
      x: {},
      y: {
        beginAtZero: true,
        suggestedMax: 20,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
        }
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType = 'bar' as const;


  public barChartData: ChartData<'bar'>
    = {
     labels: [],
     datasets: [],
   };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    // console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
  }

  ngOnInit() {

    const datasetOptions = {
      categoryPercentage: 0.8,
      barPercentage: 0.5,
    };

    if (!this.chartConfig.chartColors || this.chartConfig.chartColors.length === 0) {
      this.chartConfig.chartColors = this.utilService.chartColors;
    }

    // Ora puoi impostare i labels e i datasets insieme, includendo i colori
    this.barChartData.labels = this.chartConfig.data.labels_bar;
    const colors = this.chartConfig.chartColors; // Assumi che sia un array di colori

    this.barChartData.labels = this.chartConfig.data.labels_bar;

    // Esempio di struttura dati, adattalo secondo le tue necessità
    const dataSeries = [
      {data: this.chartConfig.data.first_bar, label:  this.chartConfig.labels[0] },
      {data: this.chartConfig.data.second_bar, label: this.chartConfig.labels[1] },
      {data: this.chartConfig.data.third_bar, label: this.chartConfig.labels[2] },
    ];

    this.barChartData.datasets = dataSeries.map((series, index) => ({
      ...datasetOptions,
      data: series.data,
      label: series.label,
      backgroundColor: this.chartConfig.chartColors[index] || 'grey',
      borderColor: this.chartConfig.chartColors[index] || 'grey',
      borderRadius: 20
    }));

    this.updateYAxisMax();
    this.configureChartOptions();

  }

  updateYAxisMax() {
    const maxValues = this.chartConfig.data.first_bar
      .concat(this.chartConfig.data.second_bar)
      .concat(this.chartConfig.data.third_bar)
      .map(Number)
      .filter(value => !isNaN(value));

    const maxValue = Math.max(...maxValues);
    const suggestedMax = maxValue + (maxValue * 0.1);

    this.barChartOptions.scales['y'].suggestedMax = Math.max(20, suggestedMax);
  }

  configureChartOptions() {
    if (this.type === 'YEAR' || this.type === '3M') {
      if (!this.barChartOptions.scales) {
        this.barChartOptions.scales = {};
      }

      if (!this.barChartOptions.scales['x']) {
        this.barChartOptions.scales['x'] = { type: 'category' };
      }

      this.barChartOptions.scales['x'].ticks = {
        callback: (val, index) => {
          // Usa l'indice per ottenere l'etichetta effettiva dall'array delle etichette
          const label = String(this.barChartData.labels[index]);
          if (label) {
            const [year, month] = label.split('-');
            const date = new Date(Date.UTC(parseInt(year), parseInt(month) - 1, 1));
            return this.utilService.translate.instant( date.toLocaleString('en-EN', { month: 'long' }) );
          }
          return '';
        }
      };
    }
  }



}
