import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-avatar',
  templateUrl: './app-avatar.component.html',
  styleUrls: ['./app-avatar.component.scss']
})
export class AppAvatarComponent implements OnInit,OnChanges {

  @Input() user?: User;
  @Input() index?: number;
  @Input() size = '24px';

  src = '';

  constructor() { }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user']) {
      this.load();
    }
  }

  async load() {
    if (this.user && this.user.avatar) {
      this.src = 'data:image/png;base64,' + this.user.avatar;
    }
  }

}
