<div class="row" [style]="'height: ' + chartHeight + 'vh;'">
  <canvas  style='z-index:1000' #chartCanvas baseChart [data]="chartData" [type]="chartConfig.type === 'pie' ? 'pie' : 'doughnut'" [options]="chartOptions"
  ></canvas>
  <div   class="center-text customCenterText">{{centerText}}</div>
</div>
<div class="row">
  <div class="chart-legend-container">
    <div *ngFor="let label of chartConfig.labels; let i = index" class="chart-legend-item">
      <span class="legend-color-box  dot" [ngStyle]="{'background-color': chartConfig.chartColors[i]}"></span>
      <span class="legend-label">{{ label | translate }}</span>
    </div>
  </div>
</div>
