<!-- Type autocomplete -->
<mat-form-field *ngIf="type === 'autocomplete'" class="app-select" appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input matInput placeholder="{{placeholder}}" [formControl]="formControl" [matAutocomplete]="auto"
        required="{{required}}" type="{{type}}" [ngModel]="property"
        (ngModelChange)="changeModel($event)" (input)="changeInput($event)">
    <mat-autocomplete #auto="matAutocomplete">
        <!-- <mat-option *ngIf="(!options || options.length <= 0) && property?.length > 0 && !utilService.loadingData" [disabled]="true">{{'no_result'}}</mat-option> -->
        <mat-option (onSelectionChange)="onSelectionChange(option)" *ngFor="let option of options" [value]="option[propertyViewKey]">{{option[propertyViewKey]}}</mat-option>
    </mat-autocomplete>
    <mat-hint align="start"><strong>{{hintStart}}</strong> </mat-hint>
    <mat-hint align="end">{{hintEnd}}</mat-hint>
    <mat-error *ngIf="formControl.hasError('required')">
         {{ 'field_required' | translate }}
    </mat-error>
</mat-form-field>
