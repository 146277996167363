import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/User';
import { AppConfigService } from './app.config.service';
import { UtilService } from './util.service';
import { RolePermissions } from '../models/Role';

@Injectable()
export class AuthService {
  private currentUser: User;
  public permissions: RolePermissions[] = [];

  set CurrentUser(value: User) {
    this.currentUser = value;
    if (value) {
      localStorage.setItem('_itusr_', btoa(unescape(encodeURIComponent(JSON.stringify(value)))));
      this.appConfigService.setAppLanguage(this.currentUser?.language);
      this.permissions = this.currentUser?.userRole?.rolePermissions;
    }
  }

  get CurrentUser(): User {
    if (localStorage.getItem('_itusr_')) {
      this.currentUser = JSON.parse(atob(localStorage.getItem('_itusr_')!));
      this.appConfigService.setAppLanguage(this.currentUser?.language);
      this.permissions = this.currentUser?.userRole?.rolePermissions;
    }
    return this.currentUser;
  }

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
    private appConfigService: AppConfigService,
  ) {
  }

  public getToken() {
    let token = localStorage.getItem('apiToken');
    if (!token) {
      token = sessionStorage.getItem('apiToken');
    }
    return token;
  }

  public isAuthenticated() {
    try {
      const token = this.getToken();
      return token ? true : null;
    } catch (error) {

    }
    return false;
  }

  public can(permissionCode: string) {
    if (this.permissions) {
      const permission = this.permissions.find(permission => permission.code === permissionCode);
      if (permission) {
        return true;
      }
    }
    return false;
  }

  public isRole(role: string) {
    if (this.CurrentUser?.userRole?.roleName === role) {
      return true;
    }
    return false;
  }

  public isService(service: string) {
    if (this.CurrentUser?.servizio === service) {
      return true;
    }
    return false;
  }

}
