<div class="row" [style]="'height: ' + chartHeight + 'vh;'">
  <canvas
  baseChart
  [data]="barChartData"
  [options]="barChartOptions"
  [type]="barChartType"
  (chartHover)="chartHovered($event)"
  (chartClick)="chartClicked($event)"
>
</canvas></div>
