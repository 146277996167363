<div class="row h-100 m-0 p-0">
    <div class="main-container col-12 d-flex flex-column align-items-center justify-content-center">
        <img [src]="appConfigService.appSettings?.logoSmall ? appConfigService?.appSettings?.logoSmall :  '../../../assets/logo.png'" alt="app logo">

        <div class="title-row row my-4 mx-0 p-0">
            <div class="col-12">
                <div class="subtitle mb-3 mt-0">
                    {{ 'download_app' | translate }}
                </div>
            </div>
        </div>

        <div class="row my-3 mx-0 p-0">
            <div class="col-12 d-flex justify-content-center">
                <button *ngIf="appConfigService.isAndroid" class="app-button fill" (click)="downloadApp('android')" mat-raised-button [disabled]="!androidUrl">
                    <mat-icon class="material-icons-outlined me-2">android</mat-icon>
                    Android
                </button>
                <button *ngIf="appConfigService.isIos" class="app-button fill" (click)="downloadApp('ios')" mat-raised-button [disabled]="!iosUrl">
                    <mat-icon class="material-icons-outlined me-2">phone_iphone</mat-icon>
                    iOS
                </button>
                <ng-container *ngIf="!appConfigService.isAndroid && !appConfigService.isIos">
                    <button class="app-button fill mx-2" (click)="downloadApp('android')" mat-raised-button [disabled]="!androidUrl">
                        <mat-icon class="material-icons-outlined me-2">android</mat-icon>
                        Android
                    </button>
                    <button class="app-button fill mx-2" (click)="downloadApp('ios')" mat-raised-button [disabled]="!iosUrl">
                        <mat-icon class="material-icons-outlined me-2">phone_iphone</mat-icon>
                        iOS
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>