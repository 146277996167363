<div class="row m-0 p-0">
    <div class="col-12 d-flex justify-content-between align-items-center" [ngClass]="{'no-padding': noPadding}">
        <div class="title-container">
            <span class="page-title" [ngClass]="{'normal-title': normalTitle, 'show': showTitle}">
                <button matTooltip="{{ 'back' | translate }}" *ngIf="showBackTitle" (click)="onBackClick($event)" mat-icon-button class="medium-icon-button ms-0 me-2">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <span style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    {{title | translate}}
                </span>
            </span>
        </div>
        <!-- <div *ngIf="showSearch" class="search-container row w-100 m-0 p-0">
            <span class="d-flex align-items-center">
                <button (click)="onSearchClick()" class="search-button" [disabled]="searchValue.length < 4">
                    <img class="search-icon"  width="24px" height="24px" src="../../../assets/search.svg">
                </button>
                <app-input class="w-100 m-0 p-0" [label]="'search' | translate" [placeholder]="'search...' | translate" [type]="'search'" [(property)]="searchValue" [maxLength]="'100'"></app-input>
                <button *ngIf="searchValue?.length > 0" (click)="onResetSearchClick()" class="search-button">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
        </div> -->
        <div class="buttons-container d-flex">
            <ng-container *ngIf="onlyIcon">
                <button matTooltip="{{ 'create' | translate }}" *ngIf="canCreate" (click)="onCreateClick($event)" mat-icon-button class="app-button" [ngClass]="{'stroked': stroked, 'small-icon-button': size === 'small', 'medium-icon-button': size === 'medium'}">
                    <mat-icon>add</mat-icon>
                </button>
                <button matTooltip="{{ 'back' | translate }}" *ngIf="type === 'detail'" (click)="onBackClick($event)" mat-icon-button class="app-button medium-icon-button back">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <button matTooltip="{{ 'cancel' | translate }}" *ngIf="type === 'edit'" (click)="onCancelClick($event)" mat-icon-button class="app-button medium-icon-button back">
                    <mat-icon>close</mat-icon>
                </button>
                <button matTooltip="{{ 'edit' | translate }}" *ngIf="type === 'detail' && canEdit" (click)="onEditClick($event)" mat-icon-button class="app-button medium-icon-button save">
                    <mat-icon>edit</mat-icon>
                </button>
                <button matTooltip="{{ 'save' | translate }}" *ngIf="type === 'edit' && canSave" (click)="onSaveClick($event)" mat-icon-button class="app-button medium-icon-button save" [disabled]="!enableSave">
                    <mat-icon>save</mat-icon>
                </button>
            </ng-container>
            

            <!-- No Icon -->
            <ng-container *ngIf="!onlyIcon">
                <button *ngIf="canCreate" class="app-button" (click)="onCreateClick($event)" mat-raised-button>
                    {{'create' | translate}}
                </button>
                <button *ngIf="type === 'detail'" class="app-button stroked" (click)="onBackClick($event)" mat-stroked-button>
                    {{'back' | translate}}
                </button>
                <button *ngIf="type === 'edit'" class="app-button stroked" (click)="onCancelClick($event)" mat-stroked-button>
                    {{'cancel' | translate}}
                </button>
                <button *ngIf="type === 'detail' && canEdit" class="app-button" (click)="onEditClick($event)" mat-raised-button>
                    {{'edit' | translate}}
                </button>
                <button  *ngIf="type === 'edit' && canSave" class="app-button" (click)="onSaveClick($event)" mat-raised-button [disabled]="!enableSave">
                    {{'save' | translate}}
                </button>
            </ng-container>
            
        </div>
    </div>
</div>
