import { Anagrafica } from "../modules/anagrafica/models/Anagrafica";
import { Role, RolePermissions } from "./Role";

export class User {
    id?: number;
    name?: string;
    surname?: string;
    phoneNumber? : string;
    email?: string;
    birthday?: string;
    status?: string;
    enabled?: boolean;
    avatar?: string;
    displayname?: string;
    isOwner?: boolean;
    language?: string;
    type?: string;
    userRole?: UserRole;
    anagraficaId?: number;
    anagrafica?: Anagrafica;
    username?: string;
    password?: string;
    ragioneSociale?: string;
    servizio?: string;
    oldPassword?: string;

    constructor() {
        this.id = 0;
        this.language = 'it';
        this.name = '';
        this.email = '';
        this.birthday = '';
        this.status = '';
        this.avatar = '';
        this.displayname = '';
    }
}

export class UserRole {
    id?: number;
    roleId: number;
    userId?: number;
    roleName?: string;
    rolePermissions: RolePermissions[];

    constructor(id: number = 0) {
        this.id = id;
    }
}
