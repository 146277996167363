import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { DownloadAppComponent } from './pages/downloadapp/downloadapp.component';
import { MobileGuard } from './mobile-guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [MobileGuard] },
  { path: 'reset-password', component: LoginComponent, canActivate: [MobileGuard] },
  { path: 'confirm-account', component: LoginComponent, canActivate: [MobileGuard] },
  { path: 'supplier-registration', component: LoginComponent, canActivate: [MobileGuard] },
  { path: 'affiliate-registration', component: LoginComponent, canActivate: [MobileGuard] },
  { path: 'revision-confirm', component: ConfirmComponent, canActivate: [MobileGuard] },
  { path: 'downloadapp', component: DownloadAppComponent },
  { path: '', loadChildren: () => import('./container/container.module').then(m => m.ContainerModule) },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
