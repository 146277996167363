<div class="table-filter-container row justify-content-between align-items-center m-0 p-0">
    <div class="col-12 mb-2 mt-0 mx-0 p-0">
        <div class="row align-items-center m-0 p-0">
            <div class="searchbar col m-0 p-0">
                <span class="d-flex align-items-center">
                    <!-- <div class="search-button-container" [ngClass]="{'opened': showFilterSection}">
                        <button mat-icon-button [matBadge]="getFilterNumbers() > 0 ? getFilterNumbers() : ''" matBadgeColor="warn" (click)="onFilterClick()" class="app-button no-background medium-icon-button ms-0 me-2" [ngClass]="{'hover': getFilterNumbers() > 0}">
                            <img class="search-icon"  width="24px" height="24px" src="../../../assets/filter.svg" alt="icon search">
                        </button>
                    </div> -->
                    <app-input class="w-100 m-0 p-0" [label]="'search' | translate" [placeholder]="'search...' | translate" [type]="'search'" [(property)]="searchValue" (inputChange)="onSearchChange()" (clearClick)="onResetSearchClick()" [maxLength]="'100'"></app-input>
                </span>
            </div>
            <div class="col actions-col d-flex m-0 p-0">
                <button *ngIf="authService.can('users:manage')" 
                    matTooltip="{{ 'create' | translate }}" (click)="goToCreate()" mat-icon-button class="app-button medium-icon-button" style="margin-left: 10px;">
                    <mat-icon>add</mat-icon>
                </button>
                <!-- <app-page-title [type]="'view'" [noPadding]="true" [canCreate]="authService.can('users:manage')" (createEvent)="goToCreate()"></app-page-title> -->
            </div>
        </div>
    </div>
</div>
<div class="table-container row m-0 p-0" [ngStyle]="{'height': 'calc(100% - ' + customTableHeight + ')'}">
    <div class="table-column col m-0 p-0 app-scrollbar mat-elevation-z8" [ngClass]="{'table-scrollbar': showPaginator}">
        <table mat-table [dataSource]="dataSource" matSort class="app-table">
    
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
    
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef hidden> ID </th>
                <td mat-cell *matCellDef="let row" hidden> {{row.id}} </td>
            </ng-container>
    
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'name' | translate }} </th>
                <td mat-cell *matCellDef="let row">
                    <div class="d-flex align-items-center">
                        <app-avatar [user]="row" class="me-2 ms-0"></app-avatar>
                        {{row.displayname ?? '-'}}
                    </div>
                </td>
            </ng-container>
    
            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
            </ng-container>
    
            <!-- Role Column -->
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> {{ 'role' | translate }} </th>
                <td mat-cell *matCellDef="let row"> {{row.userRole?.roleName ? row.userRole.roleName : '-'}} </td>
            </ng-container>
    
            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let row"> {{(row.enabled ? 'enabled' : 'disabled') | translate}} </td>
            </ng-container>
    
            <!-- Actions Column -->
            <ng-container matColumnDef="actionsdetail">
                <th mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }} </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="small-icon-button">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <button mat-icon-button (click)="goToDetail(row)" class="small-icon-button">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
    
                    <mat-menu #menu="matMenu" class="app-menu">
                        <button mat-menu-item (click)="goToEdit(row)">
                            <!-- <mat-icon class="material-icons-outlined">edit</mat-icon> -->
                            <span>{{ 'edit' | translate }}</span>
                        </button>
                        <button *ngIf="!row.isOwner" mat-menu-item (click)="toggleEnableUser(row)">
                            <!-- <mat-icon class="material-icons-outlined">{{row.enabled ? 'account_circle_off' : 'account_circle'}}</mat-icon> -->
                            <span>{{ row.enabled ? ('disable' | translate) :  ('enable' | translate)}}</span>
                        </button>
                        <button *ngIf="!row.isOwner" mat-menu-item (click)="deleteElement(row)">
                            <!-- <mat-icon class="material-icons-outlined">delete</mat-icon> -->
                            <span>{{ 'delete' | translate }}</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td *ngIf="!utilService.loadingData" class="mat-cell" colspan="5">{{ 'no_data' | translate }}</td>
                <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
            </tr>
        </table>
    
        <mat-paginator *ngIf="showPaginator" [pageSize]="pageSize" [length]="itemsCount" [showFirstLastButtons]="true" (page)="handlePageEvent($event)"></mat-paginator>
    </div>
</div>
