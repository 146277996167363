import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app.config.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ApiResponse } from '../models/apiResponse';
import { UtilService } from './util.service';
import { User } from '../models/User';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Anagrafica, Categoria } from '../modules/anagrafica/models/Anagrafica';
import { Role } from '../models/Role';
import { AlboDocumento, AlboFornitore, AlboTipoDocumento, StoricoDocumento, TipoDocumento } from '../modules/anagrafica/models/AlboDocumento';
import { AppConfig } from '../models/appConfigs';
import { Magazzino } from '../modules/magazzino/models/Magazzino';
import { CategoriaArticolo } from '../modules/magazzino/models/CategoriaArticolo';
import { TipologiaArticolo } from '../modules/magazzino/models/TipologiaArticolo';
import { Articolo } from '../modules/magazzino/models/Articolo';
import { QrCodeBlock } from '../modules/qrcode/models/QrCodeBlock';
import { ODL_TYPES, Odl, OdlNewRequests } from '../modules/logistics/models/Odl';
import { Notification } from '../models/Notification';
import { QrCode } from '../modules/qrcode/models/QrCode';
import { Process } from '../modules/process/models/Process';
import { Producer } from '../models/Producer';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private utilService: UtilService,
    private authService: AuthService,
    private router: Router
  ) { }

  private async callApi(type: string, route: string, body = {}, auth = true, version = 'v1') {
    let headers, response;
    if (auth) {
      const token = 'Bearer ' + localStorage.getItem('apiToken');
      if (token) {
        headers = new HttpHeaders({ 'Authorization': token });
      } else {
        return;
      }
    }
    try {
      switch (type) {
        case 'delete':
          response = await lastValueFrom(this.http.delete<ApiResponse>(`${this.appConfigService.environment.apiUrl}` + '/' + version + '/' + route, { headers: headers, params: body, observe: 'response' }));
          break;
        case 'put':
          response = await lastValueFrom(this.http.put<ApiResponse>(`${this.appConfigService.environment.apiUrl}` + '/' + version + '/' + route, { dto: body }, { headers: headers, observe: 'response' }));
          break;
        case 'post':
          response = await lastValueFrom(this.http.post<ApiResponse>(`${this.appConfigService.environment.apiUrl}` + '/' + version + '/' + route, { dto: body }, { headers: headers, observe: 'response' }));
          break;
        case 'get':
          response = await lastValueFrom(this.http.get<ApiResponse>(`${this.appConfigService.environment.apiUrl}` + '/' + version + '/' + route, { headers: headers, params: body, observe: 'response' }));
          break
        case 'download':
          response = await lastValueFrom(this.http.get(`${this.appConfigService.environment.apiUrl}` + '/' + version + '/' + route, { headers: headers, params: body, observe: 'response', responseType: 'arraybuffer' }));
          break
        case 'upload':
          response = await lastValueFrom(this.http.post<ApiResponse>(`${this.appConfigService.environment.apiUrl}` + '/' + version + '/' + route, body, { headers: headers, observe: 'response' }));
          break
      }
      if (response?.status === 200) {
        const token = response.headers.get('Authorization');
        if (token) {
          localStorage.setItem('apiToken', token);
        }
      }
      return response?.body;
    } catch (e: any) {
      if (e.error?.code === 401 && e.error?.error === 'Unauthenticated.') {
        this.authService.CurrentUser = null;
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['login']);
        this.utilService.openSnackbar(this.utilService.translate.instant('session_expired'));
        this.utilService.hideSpinner();
      } else if (e.error?.message) {
        this.utilService.openSnackbar(e.error.message, 'report');
      } else if (e.error?.error) {
        this.utilService.openSnackbar(e.error.error, 'report');
      } else {
        this.utilService.openSnackbar(this.utilService.translate.instant('generic_error'), 'report');
      }
      return e.error;
    }
  }

  private getQueryParams(skip, take, filters, search?: string, orderby?: any, direction?: string) {
    let queryParams = new HttpParams();
    if (skip != null && take != null && skip >= 0 && take > 0) {
      queryParams = queryParams.append('skip', skip);
      queryParams = queryParams.append('take', take);
    }
    if (filters && filters.length > 0) {
      for (let filter of filters) {
        let property = Object.keys(filter)[0];
        if (!(filter[property] instanceof Array)) {
          queryParams = queryParams.append(property, filter[property]);
        } else {
          for (let param of filter[property]) {
            queryParams = queryParams.append(property + '[]', param);
          }
        }
      }
    }
    if (search && search.length > 0) {
      queryParams = queryParams.append('search', search);
    }
    if (orderby) {
      queryParams = queryParams.append('orderby', orderby);
    }
    if (direction) {
      queryParams = queryParams.append('direction', direction);
    }
    return queryParams;
  }

  async login(username?: string, password?: string) {
    const response = await this.callApi('post', 'login', { username: username, password: password }, false);
    if (response?.performed) {
      this.authService.CurrentUser = response.dto;
    }
    return response.performed;
  }

  async logout() {
    const response = await this.callApi('get', 'logout', {});
    if (response.performed) {
      localStorage.clear();
      sessionStorage.clear();
      this.authService.CurrentUser = null;
    }
    return response.performed;
  }

  async confirmAccount(token: string, email: string, password: string, confirmPassword: string) {
    const response = await this.callApi('post', 'confirm-account', { token: token, email: email, password: password, password_confirmation: confirmPassword }, false);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('account_confirmed'), 'verified')
    }
    return response.performed;
  }

  async resetPassword(token: string, email: string, password: string, confirmPassword: string) {
    const response = await this.callApi('post', 'reset-password', { token: token, email: email, password: password, password_confirmation: confirmPassword }, false);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('password_resetted'), 'verified')
    }
    return response.performed;
  }

  async forgotPassword(email: string) {
    const response = await this.callApi('post', 'forgot-password', { email: email }, false);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('password_forgot_message'), 'forward_to_inbox')
    }
    return response.performed;
  }

  async registerProvider(token: string, username: string, name: string, surname: string, displayname: string, password: string, confirmPassword: string) {
    const response = await this.callApi('post', 'supplier-registration', { token: token, username: username, name: name, surname: surname, displayname: displayname, password: password, password_confirmation: confirmPassword }, false);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('supplier_account_created'), 'how_to_reg')
    }
    return response.performed;
  }

  async registerAffiliate(token: string, username: string, name: string, surname: string, displayname: string, password: string, confirmPassword: string) {
    const response = await this.callApi('post', 'affiliate-registration', { token: token, username: username, name: name, surname: surname, displayname: displayname, password: password, password_confirmation: confirmPassword }, false);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('affiliate_account_created'), 'how_to_reg')
    }
    return response.performed;
  }

  async confirmProviderAccount(token: string) {
    const response = await this.callApi('post', 'supplier-confirmation', { token: token }, false);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('confirmed_request'), 'verified')
    }
    return response;
  }

  async confirmRevision(token: string) {
    const response = await this.callApi('put', 'logistica/revision-confirm', { token: token }, false);
    // if (response?.performed) {
    //     this.utilService.openSnackbar(this.utilService.translate.instant('supplier_account_activated'), 'verified')
    // }
    return response;
  }

  async getAppSettings() {
    const response = await this.callApi('get', 'init-settings');
    if (response.performed) {

    }
    return response;
  }

  //User
  async createUser(user: User) {
    const response = await this.callApi('post', 'user', user);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async updateUser(user: User) {
    const response = await this.callApi('put', 'user', user);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response;
  }

  async checkPassword(data) {
    const response = await this.callApi('put', 'check-password', data);
    return response.performed;
  }

  async deleteUser(user: User) {
    const response = await this.callApi('delete', 'user', { id: user.id });
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async getUser(id: number) {
    const response = await this.callApi('get', 'user', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getUsers(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'users', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  // Role
  async createRole(role: Role) {
    const response = await this.callApi('post', 'role', role);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async updateRole(role: Role) {
    const response = await this.callApi('put', 'role', role);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async deleteRole(role: Role) {
    const response = await this.callApi('delete', 'role', { id: role.id });
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async getRole(id: number) {
    const response = await this.callApi('get', 'role', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getRoles(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'roles', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getPermissions(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'permissions', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Anagrafica
  async createAnagrafica(anagrafica: Anagrafica) {
    const response = await this.callApi('post', 'anagrafiche/anagrafica', anagrafica);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save');
      return response?.dto;
    }
    return null;
  }

  async updateAnagrafica(anagrafica: Anagrafica) {
    const response = await this.callApi('put', 'anagrafiche/anagrafica', anagrafica);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', anagrafica?.denominazione)
    }
    return response.performed;
  }

  async deleteAnagrafica(idsAnagrafica: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsAnagrafica) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'anagrafiche/anagrafica', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async inviteProviderUser(anagrafica: Anagrafica) {
    const response = await this.callApi('put', 'anagrafiche/supplier-invitation', anagrafica);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_invited'), 'mark_email_read')
    }
    return response.performed;
  }

  async inviteAffiliateUser(anagrafica: Anagrafica) {
    const response = await this.callApi('put', 'anagrafiche/affiliate-invitation', anagrafica);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_invited'), 'mark_email_read')
    }
    return response.performed;
  }

  async exportAnagraficaExcel(idsAnagrafica: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsAnagrafica) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('download', 'anagrafiche/export-excel', queryParams);
    if (response) {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = 'Anagrafiche.xlsx';
      element.click();
      return true;
    }
    return false;
  }

  async exportAnagraficaPdf(idsAnagrafica: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsAnagrafica) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('download', 'anagrafiche/export-pdf', queryParams);
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = 'Anagrafiche.pdf';
      element.click();
      return true;
    }
    return false;
  }

  async getAnagrafica(id: number): Promise<Anagrafica> {
    const response = await this.callApi('get', 'anagrafiche/anagrafica', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getAnagraficas(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'anagrafiche/anagraficas', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async searchAanagraficas(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'anagrafiche/anagraficas_search', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getAlboFornitore(id: number): Promise<AlboFornitore> {
    const response = await this.callApi('get', 'anagrafiche/albo-fornitore', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getAlboFornitores(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'anagrafiche/albos-fornitore', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async updateAlboFornitore(alboFornitore: AlboFornitore, anagrafica?: Anagrafica) {
    const response = await this.callApi('put', 'anagrafiche/albo-fornitore', alboFornitore);
    if (response?.performed) {
      if (alboFornitore.isFornitore) {
        this.utilService.openSnackbar(this.utilService.translate.instant('request_sent_successfully'), 'mark_email_read')
      } else {
        this.utilService.openSnackbar(this.utilService.translate.instant('supplier_nootified') + ' ' + anagrafica?.denominazione, 'mark_email_read')
      }
    }
    return response.performed;
  }

  async getTipologiaAnagrafica(id: number) {
    const response = await this.callApi('get', 'anagrafiche/tipologia', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null
  }

  async getTipologieAnagrafica(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'anagrafiche/tipologias', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getSettoriMerceologici(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'anagrafiche/settore-merceologicos', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getCategoria(id: number): Promise<Categoria> {
    const response = await this.callApi('get', 'anagrafiche/categoria', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getCategorie(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search);
    const response = await this.callApi('get', 'anagrafiche/categorias', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getMetodiPagamento(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'anagrafiche/metodo-pagamentos', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getFatturazioni(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'anagrafiche/fatturaziones', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getAliquoteIva(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'anagrafiche/aliquotas', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getGeocoding(queryString = null) {
    const response = await this.callApi('get', 'geocoding', { search: queryString });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getPiva(queryString = null) {
    const response = await this.callApi('get', 'imprese-advance', { search: queryString });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Albo
  async getAlboTipoDocumenti(alboId: number): Promise<AlboTipoDocumento[]> {
    const response = await this.callApi('get', 'anagrafiche/albo-tipo-documentos', { alboId: alboId });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getAlbos(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'anagrafiche/albos', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getAlboTipoDocumentis(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'anagrafiche/albo-tipo-documentos', queryParams);
    if (response.performed) {
      return response
    }
    return null;
  }

  async updateAlboTipoDocumento(alboTipoDocumento: AlboTipoDocumento) {
    const response = await this.callApi('put', 'anagrafiche/albo-tipo-documento', alboTipoDocumento);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }


  async createAlboTipoDocumento(alboTipoDocumento: AlboTipoDocumento) {
    const response = await this.callApi('post', 'anagrafiche/albo-tipo-documento', alboTipoDocumento);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save');
      return response?.dto;
    }
    return null;
  }

  async deleteAlboTipoDocumento(alboTipoDocumento: number[]) {
    const response = await this.callApi('delete', 'anagrafiche/albo-tipo-documento', { id: alboTipoDocumento });
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }


  async createTipologiaDocumento(tipoDocumento: TipoDocumento) {
    const response = await this.callApi('post', 'anagrafiche/tipologia-documento', tipoDocumento);
    if (response?.performed) {
      // this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save');
      return response?.dto;
    }
    return null;
  }

  async updateTipologiaDocumento(tipoDocumento: TipoDocumento) {
    const response = await this.callApi('put', 'anagrafiche/tipologia-documento', tipoDocumento);
    if (response?.performed) {
      // this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async deleteTipologiaDocumento(tipoDocumento: number) {
    const response = await this.callApi('delete', 'anagrafiche/tipologia-documento', { id: tipoDocumento });
    if (response?.performed) {
      // this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async getTipoDocumento(id: number) {
    const response = await this.callApi('get', 'anagrafiche/albo-tipo-documento', { id: id });
    if (response.performed) {
      return response.dto
    }
    return null;
  }

  async getAlboDocumenti(alboFornitoreId: number): Promise<AlboDocumento[]> {
    const response = await this.callApi('get', 'anagrafiche/albo-documentos', { alboFornitoreId: alboFornitoreId });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getStoricoAlboDocumenti(alboDocumentoId: number): Promise<AlboTipoDocumento[]> {
    const response = await this.callApi('get', 'anagrafiche/albo-documento-history', { id: alboDocumentoId });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async uploadAlboDocumento(alboDocumento: AlboDocumento) {
    const formData = new FormData();
    formData.append("dto", JSON.stringify(alboDocumento));
    formData.append("file", alboDocumento.file);
    const response = await this.callApi('upload', 'anagrafiche/albo-documento', formData);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_uploaded'), 'file_upload')
    }
    return response.performed;
  }

  async deleteAlboDocumento(alboDocumento: AlboDocumento) {
    const response = await this.callApi('delete', 'anagrafiche/albo-documento', { id: alboDocumento.id });
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async updateStatoVerifica(alboDocumento: AlboDocumento) {
    const response = await this.callApi('put', 'anagrafiche/stato-verifica', alboDocumento);
    if (response?.performed) {
      // this.utilService.openSnackbar(this.utilService.translate.instant('request_sent_successfully'), 'mark_email_read')
    }
    return response.performed;
  }

  async viewAlboDocumento(alboDocumento: AlboDocumento) {
    if (alboDocumento && alboDocumento.url) {
      const response = await this.callApi('get', 'anagrafiche/media-token');
      if (response && response.dto?.token) {
        const element = document.createElement('a');
        element.href = alboDocumento?.url + '?token=' + response.dto?.token;
        element.target = '_blank';
        element.click();

        return true;
      }
    }
    return false;
  }

  async downloadAlboDocumento(idAlboDocumento: number, nomeTipoDocumento: string) {
    const response = await this.callApi('download', 'anagrafiche/albo-documento-download', { id: idAlboDocumento });
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = nomeTipoDocumento;
      element.click();

      return true;
    }
    return false;
  }

  async downloadStoricoAlboDocumento(idAlboDocumento: number, storicoDocumento: StoricoDocumento) {
    const response = await this.callApi('download', 'anagrafiche/history-documento-download', { id: idAlboDocumento, mediaId: storicoDocumento.id });
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = storicoDocumento.filename;
      element.click();

      return true;
    }
    return false;
  }

  async getAppConfigs(filters: any = null, search = null) {
    let queryParams = this.getQueryParams(null, null, filters, search);
    const response = await this.callApi('get', 'appconfigs', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async updateAppConfig(data: AppConfig[]) {
    const response = await this.callApi('put', 'appconfig', data);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response;
  }

  async createAppConfig(data: AppConfig) {
    data.id = 0;
    const response = await this.callApi('post', 'appconfig', data);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async deleteAppConfig(idAppConfig: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('id', idAppConfig);
    const response = await this.callApi('delete', 'appconfig', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  // Magazzino
  async createMagazzino(element: Magazzino) {
    const response = await this.callApi('post', 'logistica/magazzino', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async updateMagazzino(element: Magazzino) {
    const response = await this.callApi('put', 'logistica/magazzino', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', element?.nome)
    }
    return response.performed;
  }

  async deleteMagazzino(idsMagazzino: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsMagazzino) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'logistica/magazzino', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async getMagazzino(id: number) {
    const response = await this.callApi('get', 'logistica/magazzino', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getMagazzinos(skip: number, take: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search);
    const response = await this.callApi('get', 'logistica/magazzinos', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  // Categorie Articoli
  async createCategoriaArticolo(element: CategoriaArticolo) {
    const response = await this.callApi('post', 'logistica/categoria-articolo', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async updateCategoriaArticolo(element: CategoriaArticolo) {
    const response = await this.callApi('put', 'logistica/categoria-articolo', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', element?.nome)
    }
    return response.performed;
  }

  async deleteCategoriaArticolo(idsMagazzino: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsMagazzino) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'logistica/categoria-articolo', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async getCategoriaArticolo(id: number) {
    const response = await this.callApi('get', 'logistica/categoria-articolo', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getCategoriaArticolos(skip?: number, take?: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/categorias-articolo', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getUnitaMisuras(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters);
    const response = await this.callApi('get', 'logistica/unitas-misura', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getCategoriasMagazzino(skip?: number, take?: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/categorias-magazzino', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async assignCategoriaMagazzino(categoriaArticoloId: number, magazzinoId: number) {
    const response = await this.callApi('post', 'logistica/categoria-magazzino', { id: 0, categoriaArticoloId: categoriaArticoloId, magazzinoId: magazzinoId });
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async removeCategoriaMagazzino(idsCategoriaMagazzino: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsCategoriaMagazzino) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'logistica/categoria-magazzino', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }
  //Categoria Anagrafica
  async createCategory(element: Categoria) {
    const response = await this.callApi('post', 'anagrafiche/categoria', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', element?.nome)
    }
    return response.performed;
  }

  async updateCategory(element: Categoria) {
    const response = await this.callApi('put', 'anagrafiche/categoria', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', element?.nome)
    }
    return response.performed;
  }

  async deleteCategory(idsCategory: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsCategory) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'anagrafiche/categoria', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  // Producers
  async createProducer(element: Producer) {
    const response = await this.callApi('post', 'logistica/manufacturer', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', element?.name)
    }
    return response.performed;
  }

  async getProducer(id: number): Promise<Producer> {
    const response = await this.callApi('get', 'logistica/manufacturer', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getProducers(skip?: number, take?: number, filters: any = null, search = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search);
    const response = await this.callApi('get', 'logistica/manufacturers', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async updateProducer(element: Producer) {
    const response = await this.callApi('put', 'logistica/manufacturer', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', element?.name)
    }
    return response.performed;
  }

  async deleteProducer(idsCategory: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsCategory) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'logistica/manufacturer', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  // Tipologie Articoli
  async createTipologiaArticolo(element: TipologiaArticolo) {
    const response = await this.callApi('post', 'logistica/tipologia-articolo', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save');
      return response?.dto;
    }
    return null;
  }

  async updateTipologiaArticolo(element: TipologiaArticolo) {
    const response = await this.callApi('put', 'logistica/tipologia-articolo', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save', element?.nome)
    }
    return response.performed;
  }

  async deleteTipologiaArticolo(idsMagazzino: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsMagazzino) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'logistica/tipologia-articolo', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async getTipologiaArticolo(id: number) {
    const response = await this.callApi('get', 'logistica/tipologia-articolo', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getTipologiaArticolos(skip?: number, take?: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/tipologias-articolo', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getTipologiasMagazzino(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/tipologias-magazzino', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async assignTipologiaMagazzino(categoriaMagazzinoId: number, tipologiaArticoloId: number, magazzinoId: number) {
    const response = await this.callApi('post', 'logistica/tipologia-magazzino', { id: 0, categoriaMagazzinoId: categoriaMagazzinoId, tipologiaArticoloId: tipologiaArticoloId, magazzinoId: magazzinoId });
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async removeTipologiaMagazzino(idsCategoriaMagazzino: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsCategoriaMagazzino) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('delete', 'logistica/tipologia-magazzino', queryParams);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  // Articoli
  async getArticolos(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/articolos', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getArticolo(id: number): Promise<Articolo> {
    const response = await this.callApi('get', 'logistica/articolo', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }


  async createArticolo(element: Articolo) {
    const response = await this.callApi('post', 'logistica/articolo', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save');
      return response?.dto;
    }
    return null;
  }

  async updateArticolo(element: Articolo) {
    const response = await this.callApi('put', 'logistica/articolo', element);
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  async importArticolo(xlsFile, mapFile) {
    const formData = new FormData();
    formData.append("dto", JSON.stringify(mapFile));
    formData.append("file", xlsFile.file);
    const response = await this.callApi('upload', 'logistica/import-excel', formData);
    if (response?.performed) {
      if (response?.dto && response.dto.code) {
        this.utilService.openSnackbar(this.utilService.translate.instant('successfully_uploaded_file'), 'file_upload')
      } else {
        this.utilService.openSnackbar(this.utilService.translate.instant('import_failure'), 'file_upload_off', null, true)
      }
    }
    return response;
  }

  async getVirtualDbArticolos(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/items-inventory', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async importVirtualDbArticolo(xlsFile, mapFile) {
    const formData = new FormData();
    formData.append("dto", JSON.stringify(mapFile));
    formData.append("file", xlsFile.file);
    const response = await this.callApi('upload', 'logistica/items-inventory-import', formData);
    if (response?.performed) {
      if (response?.dto && response.dto.code) {
        this.utilService.openSnackbar(this.utilService.translate.instant('successfully_uploaded_file'), 'file_upload')
      } else {
        this.utilService.openSnackbar(this.utilService.translate.instant('import_failure'), 'file_upload_off', null, true)
      }
    }
    return response;
  }

  // Notifications
  async getNotifications(filters: any = null, search = null, orderby = null, direction = null): Promise<Notification[]> {
    let queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);
    const response = await this.callApi('get', 'notifications', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async readNotifications(notificationId: number = null, markAll: boolean = false) {
    const response = await this.callApi('put', 'notification', { id: notificationId, markAll: markAll });
    if (response?.performed) {
      // this.utilService.openSnackbar(this.utilService.translate.instant('successfully_saved'), 'save')
    }
    return response.performed;
  }

  // QR CODE
  async generateQrCodes(qrCodeBlock: QrCodeBlock) {
    const response = await this.callApi('post', 'qrcode-package', qrCodeBlock);
    if (response?.performed) {
      let message = this.utilService.translate.instant('blocks_genarated');
      message = message.replace(/:count/i, response.count);
      this.utilService.openSnackbar('<b>' + message + '</b>', 'qr_code_2_add', 'QR Code', true)
    }
    return response.performed;
  }

  async getQrCodeBlock(id: number): Promise<QrCodeBlock> {
    const response = await this.callApi('get', 'qrcode-package', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getQrCodeBlocks(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'qrcode-packages', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getQrCodes(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'qrcodes', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async downloadQrCodeBlock(qrCodeBlock: QrCodeBlock) {
    const response = await this.callApi('download', 'qrcode-package-download', { id: qrCodeBlock.id });
    if (response) {
      const blob = new Blob([response], { type: 'application/zip' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = qrCodeBlock.code;
      element.click();

      return true;
    }
    return false;
  }

  async downloadQrCode(qrCode: QrCode) {
    const response = await this.callApi('download', 'qrcode-download', { id: qrCode.id });
    if (response) {
      const blob = new Blob([response], { type: 'image/png' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = qrCode.code;
      element.click();
      return true;
    }
    return false;
  }

  // Process
  async getProcesses(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/report-items', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async downloadProcess(process: Process) {
    const response = await this.callApi('download', 'logistica/report-download', { id: process.id });
    if (response) {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = process.code;
      element.click();

      return true;
    }
    return false;
  }

  // Logistics
  async createOdl(data: Odl) {
    const response = await this.callApi('post', 'logistica/odl', data);
    if (response.performed) {
      return response?.dto;
    }
    return null;
  }

  async updateOdl(data: Odl) {
    const response = await this.callApi('put', 'logistica/odl', data);
    if (response.performed) {
      return response?.dto;
    }
    return null;
  }

  async getOdl(id: number): Promise<Odl> {
    const response = await this.callApi('get', 'logistica/odl', { id: id });
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  async getOdls(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/odls', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async deleteOdl(id: number) {
    const response = await this.callApi('delete', 'logistica/odl', { id: id });
    if (response?.performed) {
      this.utilService.openSnackbar(this.utilService.translate.instant('successfully_deleted'), 'delete')
    }
    return response.performed;
  }

  async closeOdl(data: Odl) {
    const response = await this.callApi('put', 'logistica/odl-close', data);
    if (response.performed) {
      this.utilService.openSnackbar('Odl' + ' <b>' + data.code + '</b> ' + this.utilService.translate.instant('successfully_completed'), 'train', this.utilService.translate.instant('odl_closed'))
      return response?.dto;
    }
    return null;
  }

  async registerVehicle(data: Odl) {
    const response = await this.callApi('post', 'logistica/register-vehicle', data);
    if (response.performed) {
      return response?.dto;
    }
    return null;
  }

  async combineShipping(data: Odl) {
    const response = await this.callApi('post', 'logistica/combine-shipping', data);
    if (response.performed) {
      return response?.dto;
    }
    return null;
  }

  async getDepositors(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/warehouses-depositors', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getServices(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/warehouses-services', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getManagers(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'technical-managers', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async exportOdlExcel(idsOdl: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsOdl) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('download', 'odls/export-excel', queryParams);
    if (response) {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = 'Odls.xlsx';
      element.click();
      return true;
    }
    return false;
  }

  async exportOdlPdf(idsOdl: number[]) {
    let queryParams = new HttpParams();
    for (let id of idsOdl) {
      queryParams = queryParams.append('id[]', id);
    }
    const response = await this.callApi('download', 'odls/export-pdf', queryParams);
    if (response) {
      const blob = new Blob([response], { type: 'application/pdf' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = 'Odls.pdf';
      element.click();
      return true;
    }
    return false;
  }

  async getTrackings(skip: number, take: number, filters: any = null, search = null, orderby = null, direction = null) {
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'logistica/trackings', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async getTracking(id: number) {
    const response = await this.callApi('get', 'logistica/track', { id: id });
    if (response.performed) {
      return response;
    }
    return null;
  }

  // Integrazione API Dashboard - Inizio

  // Dashboard - Card Alert - Anagrafiche
  async getAlertAnagrafiche(filters: any = null, search = null, orderby = null, direction = null): Promise<any> {
    let queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);
    const response = await this.callApi('get', 'dashboard-alert', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Dashboard - Card Alert - Anagrafiche fornitori - espansione
  async getAlertFornitoriExpansion(): Promise<any> {
    let queryParams = this.getQueryParams(null, null, []);
    const response = await this.callApi('get', 'dashboard-alert?tipo=FORNITORE&explode=docs', queryParams);
    if (response.performed) {

      return response.dto;
    }
    return null;
  }

  // async getAlertFornitoriExpansionfilters: any = null, search = null, orderby = null, direction = null): Promise<any> {
  //   let queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);
  //   const response = await this.callApi('get', 'dashboard-alert', queryParams);
  //   if (response.performed) {
  //     console.log('Dati chiamata API getAlertFornitoriExpansion:', response.dto);

  //     return response.dto;
  //   }
  //   return null;
  // }

  // Dashboard - Card Alert - Articoli
  async getAlertArticoli(filters: any = null, search = null, orderby = null, direction = null): Promise<any> {
    let queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);
    const response = await this.callApi('get', 'dashboard-alert', queryParams);
    if (response.performed) {

      return response.dto;
    }
    return null;
  }

  // Dashboard - Card Alert - Articoli - espansione
  async getAlertArticoliExpansion(filters: any = null, search = null, orderby = null, direction = null): Promise<any> {
    let queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);
    const response = await this.callApi('get', 'dashboard-alert?tipo=FORNITORE&explode=docs', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Dashboard - Card Nuove Richieste - ODL
  async getDashboardNewRequestOdl(filters: any = null, search = null, orderby = null, direction = null): Promise<any> {
    let queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);

    const response = await this.callApi('get', 'dashboard-new-request-odl', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  // Dashboard - Card Nuove Richieste - Fornitore
  async getDashboardNewRequestFornitore(filters: any = null, search = null, orderby = null, direction = null): Promise<any> {
    const queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);
    const response = await this.callApi('get', 'dashboard-new-request?tipo=FORNITORE', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Dashborad - Card Magazzini
  async getMagazziniDashboard(filters: any[] = [], search: string = ''): Promise<any> {
    let queryParams = this.getQueryParams(null, null, filters, search);
    const response = await this.callApi('get', 'dashboard-magazzino', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Dashborad - Card Alert Articoli Details
  async getArticoliByDisponibilita(disponibilita: string, filters: any[] = [], search: string = '') {
    let queryParams = this.getQueryParams(null, null, filters, search);
    queryParams = queryParams.append('disponibilita', disponibilita);
    const response = await this.callApi('get', 'dashboard-magazzino', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Dashboard - Card Anagrafica
  async getDashboardAnagrafica(filters: any = null, search = null, orderby = null, direction = null): Promise<any> {
    let queryParams = this.getQueryParams(null, null, filters, search, orderby, direction);
    const response = await this.callApi('get', 'dashboard-anagrafica', queryParams);

    if (response.performed) {
      return response.dto;
    }
    return null;
  }

  // Dashboard - Card ODL - Tipologia
  async getDashboardODLsByType(): Promise<any> {
    const queryParams = this.getQueryParams(null, null, []);
    const response = await this.callApi('get', 'dashboard-odls?filter=type', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;

  }

  // Dashboard - Card ODL - Stato
  async getDashboardODLsByStatus(): Promise<any> {
    const queryParams = this.getQueryParams(null, null, []);
    const response = await this.callApi('get', 'dashboard-odls?filter=status', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;

  }

  //Activity Log

  async getActivityLog(skip?: number, take?: number, filters: any = null, search = null, orderby = null, direction=null){
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('get', 'activity-logs', queryParams);
    if (response.performed) {
      return response;
    }
    return null;
  }

  async exportActivityLog(skip?: number, take?: number, filters: any = null, search = null, orderby = null, direction=null){
    let queryParams = this.getQueryParams(skip, take, filters, search, orderby, direction);
    const response = await this.callApi('download', 'export-activity-csv', queryParams);
    if (response) {
      const blob = new Blob([response], { type: 'text/csv' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.download = 'ActivityLog.csv';
      element.click();

      return true;
    }
    return false;
  }

  // Dashboard - Card Monitoriaggio ODL
  /**
   *
   * @param filters
   * Deve contenere:
   * filter - obbligatorio, deve valere 'type'
   * startDate - Data in formato YYYY-MM-DD - obbligatorio
   * endDate - Data in formato YYYY-MM-DD - obbligatorio
   * step - indica come aggregare i dati - obbligatorio - uno tra 'year', 'month', 'day'
   * Esempio di utilizzo
   * GET /dashboard-odls-monitoring?filter=type&startDate=2024-01-01&endDate=2025-01-01&step=year
   * @returns mixed
   */
  async getDashboardODLsMonitoring(filters: any = null): Promise<any> {
    const queryParams = this.getQueryParams(null, null, filters);
    const response = await this.callApi('get', 'dashboard-odls-monitoring', queryParams);
    if (response.performed) {
      return response.dto;
    }
    return null;

  }

  // Integrazione API Dashboard - fine

}
