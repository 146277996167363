import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app.config.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  token = null;
  message = '';
  success = false;
  fail = false;
  constructor(
    public appConfigService: AppConfigService,
    private utilService: UtilService,
    public router: Router,
    private dataService: DataService,
    private route: ActivatedRoute
    ) {
    this.token = this.route.snapshot.queryParamMap.get('token');
  }

  ngOnInit() {
    if (!this.token) {
      this.router.navigate(['login']);
    }
    this.submit();
  }

  async submit() {
    if (this.router.url.includes('/revision-confirm')) {
      const response = await this.dataService.confirmRevision(this.token);
      if (response && response.performed) {
        this.message ='confirmed_request';
        this.success = true;
        this.fail = false;
      } else {
        this.message = response.message;
        this.fail = true;
        this.success = false;
      }
    }
  }
}
