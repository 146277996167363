import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Chart, ChartData, ChartOptions  } from 'chart.js';
import { UtilService } from 'src/app/services/util.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DoughnutChartConfig } from 'src/app/models/ChartConfig';

Chart.register(ChartDataLabels);


@Component({
  selector: 'app-donut-chart',
  templateUrl: './app-donut-chart.component.html',
  styleUrls: ['./app-donut-chart.component.scss'],
})
export class AppDonutChartComponent implements OnInit {

  @Input() chartConfig = new DoughnutChartConfig();
  @Input() chartHeight = 25;

  public chartData!: ChartData<'pie' | 'doughnut'>;
  public chartOptions: ChartOptions<'pie' | 'doughnut'> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: true,
        labels: {
          usePointStyle: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 14
          }
        }
      },
      tooltip: {
        boxPadding: 8,
        enabled: true,
        callbacks: {
          label: (tooltipItems) => {
            return tooltipItems.formattedValue + this.chartConfig.datalabelUnit;
          }
        }
      },
      datalabels: {
        backgroundColor: function(context) {
          return context.dataset.backgroundColor as string;
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 2,
        color: 'white',
        display: function(context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex] as number;
          return value > count * 1.5;
        },
        font: {
          weight: 'bold',
        },
        padding: 6,
        formatter: Math.round
      }
    },
    elements: {
      arc: {
        borderWidth: 4,
      },
    },
  };


  constructor(
    private utilService: UtilService
  ) {
  };


  ngOnInit() {
    if (this.chartConfig.chartColors.length <= 0) {
      this.chartConfig.chartColors = this.utilService.chartColors;
    }
    this.load();
  }

  async load() {
    this.chartData = {
      datasets: [
        {
          label: this.chartConfig.data.label,
          data: this.chartConfig.data.values,
          backgroundColor: this.chartConfig.chartColors,
          hoverBackgroundColor: this.chartConfig.chartColors,
          hoverBorderColor: '#fff',
          borderRadius: this.chartConfig.borderRadius,
          datalabels: {
            formatter: (val) => {
              return val + this.chartConfig.datalabelUnit;
            },
            anchor: 'center',
            backgroundColor: null,
            borderWidth: this.chartConfig.datalabelsBorderWidth,
            color: this.chartConfig.datalabelsColor,
            borderColor: this.chartConfig.datalabelsBorderColor,
            font: function(context) {
              var width = context.chart.width;
              var height = context.chart.height;
              var size = Math.round(height / 16);
                return {
                  size: size,
                };
            }
          }
        }
      ]
    };
    this.chartData.labels = this.chartConfig.labels;
  }

  ngOnChanges(simpleChanges: SimpleChange) {
    this.load();
  }

  ngOnDestroy() {
  }

}
