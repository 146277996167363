import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app.config.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-downloadapp',
  templateUrl: './downloadapp.component.html',
  styleUrls: ['./downloadapp.component.scss']
})
export class DownloadAppComponent implements OnInit {
  androidUrl: string;
  iosUrl: string;
  androidBundle: string;
  iosBundle: string;
  constructor(
    public appConfigService: AppConfigService,
    private utilService: UtilService
  ) {
  }

  ngOnInit(): void {
    this.load();
  }

  async load() {
    this.utilService.showSpinner();
    this.utilService.hideSpinner();
  }

  async downloadApp(os: string) {
    if (os === 'android') {
      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = this.androidUrl;
      // a.download = filename;
      a.click();
    } else if (os === 'ios') {
      const a: any = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = this.iosUrl;
      // a.download = filename;
      a.click();
    }
  }

}
