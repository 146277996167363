import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { AppConfigService } from './services/app.config.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppMaterialModule } from './app-material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CoreComponentsModule } from './components/core.components.module';
import { AuthService } from './services/auth.service';
import { GooglePlaceModule } from 'ngx-gplaces-autocomp';
import { DatePipe, registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
import { QRCodeModule } from 'angularx-qrcode';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { DownloadAppComponent } from './pages/downloadapp/downloadapp.component';
import { MobileGuard } from './mobile-guard';
registerLocaleData(it);

export function appInit(appConfigService: AppConfigService) {
  return () => appConfigService.appInit();
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ConfirmComponent,
    DownloadAppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreComponentsModule ,
    NgxSpinnerModule,
    GooglePlaceModule,
    HttpClientModule,
    AppMaterialModule,
    QRCodeModule,
    MatNativeDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    })
  ],
  providers: [
    AppConfigService,
    AuthService,
    MobileGuard,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfigService]
    },
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: LOCALE_ID, useValue: 'it-IT' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
