export class DoughnutChartConfig {
    data: AppChartData;
    dataGroup: AppChartData[] | null;
    type: string;
    labels: string[];
    borderRadius: number;
    borderJoinStyle: string;
    chartColors: string[];
    datalabelsColor: string;
    datalabelsBorderColor: string;
    datalabelsBorderWidth: number;
    datalabelUnit: string;
    total : string | null;
    label: string | null;
    values: number[] | null;
    color: string | null;
    active: number | null;
    notActive: number | null;
    third: number | null;
    typeLabel: string | null;

    constructor() {
        this.data = new AppChartData();
        this.type = 'doughnut';
        this.labels = [];
        this.borderRadius = 20;
        this.borderJoinStyle = 'miter'
        this.chartColors = [];
        this.datalabelsColor = 'white';
        this.datalabelsBorderColor = 'white';
        this.datalabelsBorderWidth = 0;
        this.datalabelUnit = '';
        this.label = '';
        this.values = [];
        this.color = '';
        this.active = 0;
        this.notActive = 0;
    }
}

export class LineChartConfig {
    data: AppChartData[];
    type: string;
    labels: string[];
    borderRadius: number;
    chartColors: string[];
    stepSize!: number;
    max!: number;
    lineBackground: boolean;
    gridLines: boolean;
    datalabelUnit: string;

    constructor() {
        this.data = [];
        this.type = 'line';
        this.labels = [];
        this.borderRadius = 0;
        this.chartColors = [];
        this.lineBackground = true;
        this.gridLines = true;
        this.datalabelUnit = '';
    }
}

export class BarChartConfig {
  data: AppChartData;
  dataGroup: AppChartData[] | null;
  type: string;
  labels: string[];
  borderRadius: number | null;
  chartColors: string[];
  stepSize!: number  | null ;
  max!: number  | null;
  lineBackground: boolean  | null;
  gridLines: boolean  | null;
  datalabelUnit: string  | null;

  constructor() {
      this.data = new AppChartData();
      this.type = 'line';
      this.labels = [];
      this.borderRadius = 0;
      this.chartColors = [];
      this.lineBackground = true;
      this.gridLines = true;
      this.datalabelUnit = '';

  }
}

export class AppChartData {
    color: string[] | null;
    label: string | null;
    values: number[];
    active: number | null;
    notActive: number | null;
    third: number | null;

    first_bar:  number[] | null;
    second_bar:  number[]  | null;
    third_bar:  number[] | null;
    labels_bar: string[] | null;

    constructor() {
        this.label = '';
        this.values = [];
    }
}
