<div class="row m-0 p-0 justify-content-between" [ngClass]="{'no-padding': noPadding, 'no-margin': noMargin}">
    <div class="buttons-container">
        <ng-container *ngIf="onlyIcon">
            <button matTooltip="{{ 'back' | translate }}" *ngIf="type === 'detail' || onlyBack" (click)="onBackClick($event)" mat-icon-button class="app-button medium-icon-button back">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <button matTooltip="{{ 'cancel' | translate }}" *ngIf="type === 'edit' && !onlyBack" (click)="onCancelClick($event)" mat-icon-button class="app-button medium-icon-button back">
                <mat-icon>close</mat-icon>
            </button>
        </ng-container>
        

        <!-- No Icon -->
        <ng-container *ngIf="!onlyIcon">
            <button *ngIf="type === 'detail' || onlyBack" class="app-button stroked" (click)="onBackClick($event)" mat-stroked-button>
                {{'back' | translate}}
            </button>
            <button *ngIf="type === 'edit' && !onlyBack" class="app-button stroked" (click)="onCancelClick($event)" mat-stroked-button [disabled]="!enableCancel">
                {{'cancel' | translate}}
            </button>
        </ng-container>

    </div>
    <div class="buttons-container">
        <ng-container *ngIf="onlyIcon">
            <button matTooltip="{{ 'create' | translate }}" *ngIf="canCreate" (click)="onCreateClick($event)" mat-icon-button class="app-button" [ngClass]="{'stroked': stroked, 'small-icon-button': size === 'small', 'medium-icon-button': size === 'medium'}">
                <mat-icon>add</mat-icon>
            </button>
            <button matTooltip="{{ 'edit' | translate }}" *ngIf="type === 'detail' && canEdit" (click)="onEditClick($event)" mat-icon-button class="app-button medium-icon-button save">
                <mat-icon>edit</mat-icon>
            </button>
            <button matTooltip="{{ 'save' | translate }}" *ngIf="type === 'edit' && canSave" (click)="onSaveClick($event)" mat-icon-button class="app-button medium-icon-button save" [disabled]="!enableSave">
                <mat-icon>save</mat-icon>
            </button>
            <button matTooltip="{{ 'forward' | translate }}" *ngIf="type === 'edit' && canForward" (click)="onForwardClick($event)" mat-icon-button class="app-button medium-icon-button save" [disabled]="!enableForward">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </ng-container>
        

        <!-- No Icon -->
        <ng-container *ngIf="!onlyIcon">
            <button *ngIf="canCreate" class="app-button" (click)="onCreateClick($event)" mat-raised-button>
                {{'create' | translate}}
            </button>
            <button *ngIf="type === 'detail' && canEdit" class="app-button" (click)="onEditClick($event)" mat-raised-button>
                {{'edit' | translate}}
            </button>
            <button  *ngIf="type === 'edit' && canSave" class="app-button" (click)="onSaveClick($event)" mat-raised-button [disabled]="!enableSave">
                {{saveLabel | translate}}
            </button>
            <button  *ngIf="type === 'edit' && canForward" class="app-button" (click)="onForwardClick($event)" mat-raised-button [disabled]="!enableForward">
                {{forwardLabel | translate}}
            </button>
        </ng-container>
        
    </div>
</div>
