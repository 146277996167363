<div class="table-filter-container row justify-content-between align-items-center m-0 p-0">
    <div class="col-12 mb-2 mt-0 mx-0 p-0">
        <div class="row align-items-center m-0 p-0">
            <div class="searchbar col m-0 p-0">
            </div>
            <div class="col actions-col d-flex m-0 p-0">
                <app-page-title [type]="'view'" [noPadding]="true" [canCreate]="authService.can('roles:manage')" (createEvent)="goToCreate()"></app-page-title>
            </div>
        </div>
    </div>
</div>
<div class="table-container row m-0 p-0" [ngStyle]="{'height': 'calc(100% - ' + customTableHeight + ')'}">
    <div class="table-column col m-0 p-0 app-scrollbar mat-elevation-z8" [ngClass]="{'table-scrollbar': showPaginator}">
        <table mat-table [dataSource]="dataSource" matSort class="app-table">
    
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
    
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef hidden> ID </th>
                <td mat-cell *matCellDef="let row" hidden> {{row.id}} </td>
            </ng-container>
    
            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'name' | translate }} </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>
    
            <!-- Permission numbers Column -->
            <ng-container matColumnDef="permission_number">
                <th mat-header-cell *matHeaderCellDef> {{ 'permissions' | translate }} </th>
                <td mat-cell *matCellDef="let row"> {{row.rolePermissions ? row.rolePermissions.length : 0}} </td>
            </ng-container>
    
            <!-- Actions Column -->
            <ng-container matColumnDef="actionsdetail">
                <th mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }} </th>
                <td mat-cell *matCellDef="let row">
                    <button matTooltip="{{ 'system_role' | translate }}" *ngIf="isSystemRole(row)" mat-icon-button class="small-icon-button">
                        <mat-icon class="material-icons-outlined">info</mat-icon>
                    </button>
                    <button *ngIf="!isSystemRole(row)" mat-icon-button [matMenuTriggerFor]="menu" class="small-icon-button">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <button mat-icon-button (click)="goToDetail(row)" class="small-icon-button">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
    
                    <mat-menu #menu="matMenu" class="app-menu">
                        <button mat-menu-item (click)="goToEdit(row)">
                            <!-- <mat-icon class="material-icons-outlined">edit</mat-icon> -->
                            <span>{{ 'edit' | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="deleteElement(row)">
                            <!-- <mat-icon class="material-icons-outlined">delete</mat-icon> -->
                            <span>{{ 'delete' | translate }}</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td *ngIf="!utilService.loadingData" class="mat-cell" colspan="3">{{ 'no_data' | translate }}</td>
                <!-- <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td> -->
            </tr>
        </table>
    
        <mat-paginator *ngIf="showPaginator" [pageSize]="pageSize" [length]="itemsCount" [showFirstLastButtons]="true" (page)="handlePageEvent($event)"></mat-paginator>
    </div>
</div>