import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Chart, ChartData, ChartOptions, TooltipModel   } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DoughnutChartConfig } from 'src/app/models/ChartConfig';
import { UtilService } from 'src/app/services/util.service';


Chart.register(ChartDataLabels);

@Component({
  selector: 'app-multi-donut-chart',
  templateUrl: './app-multi-donut-chart.component.html',
  styleUrls: ['./app-multi-donut-chart.component.scss'],
})
export class AppMultiDonutChartComponent implements OnInit {

  @Input() chartConfig = new DoughnutChartConfig();
  @Input() chartHeight = 24;
  @Input() centerText: string;
  @Input() spacing: number = 0.1;
  @Input() cutout: string;
  @Input() name: string;


  public chartData!: ChartData<'pie' | 'doughnut'>;
  public chartOptions: ChartOptions<'pie' | 'doughnut'> = {
    radius:'100',
    maintainAspectRatio: false,
    spacing: this.spacing,
    responsive:true,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          usePointStyle: true,
          color: 'white',
          font: {
            size: 14
          }
        }
      },
      tooltip: {
        boxPadding: 8,
        backgroundColor: 'white', // Imposta il colore di sfondo desiderato
        titleColor: 'black', // Imposta il colore del titolo della tooltip
        bodyColor: 'black', // Imposta il colore del testo principale della tooltip
        borderColor: '#d9d9d9', // Imposta il colore del bordo della tooltip
        borderWidth: 1,
        enabled: true,
        callbacks: {
          label: (tooltipItems) => {
            // Supponendo che ogni elemento di dataGroup abbia le proprietà 'active' e 'notActive'
            let label = this.chartConfig.dataGroup[tooltipItems.datasetIndex].label || '';
            let active = this.chartConfig.dataGroup[tooltipItems.datasetIndex].active || 0;
            let notActive = this.chartConfig.dataGroup[tooltipItems.datasetIndex].notActive || 0;
            return [
              label,
              this.utilService.translate.instant('actives')+': ' + active,
              this.utilService.translate.instant('not_actives')+': ' + notActive
            ];
          }
        }
      },
      datalabels: {
        backgroundColor: function(context) {
          return context.dataset.backgroundColor as string;
        },
        borderColor: 'white',
        borderRadius: 25,
        borderWidth: 2,
        color: 'white',
        display: false,
        // display: function(context) {
        //   var dataset = context.dataset;
        //   var count = dataset.data.length;
        //   var value = dataset.data[context.dataIndex] as number;
        //   return value > count * 1.5;
        // },
        font: {
          weight: 'bold',
        },
        padding: 6,
        formatter: Math.round
      }
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  customTextPlugin: {
    id: string; beforeDraw: (chart: {
      ctx: any; width: any; height: any;
    }) => void;
  };



  constructor(
    private utilService: UtilService
  ) {
  };

  registerCustomTooltip() {

    const customTooltip = (context: any) => {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');
      // Crea l'elemento se non esiste
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<div></div>';
        document.body.appendChild(tooltipEl);
      }

      // Nasconde se non c'è tooltip
      const tooltipModel: TooltipModel<'doughnut'> = context.tooltip;
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }
      // Imposta il testo all'interno della tooltip
      if (tooltipModel.body) {
        if(this.name === 'anagrafica'){
          const total = this.chartConfig.dataGroup.reduce((acc, data) => acc + data.active + data.notActive, 0);
          const active = this.chartConfig.dataGroup[tooltipModel.dataPoints[0].datasetIndex].active;
          const notActive = this.chartConfig.dataGroup[tooltipModel.dataPoints[0].datasetIndex].notActive;

          tooltipEl.innerHTML = `
            <div style='z-index:1070;'>
            <div style="font-size: 22px; margin: 4px 0; color: black;">
              Totale: ${total}
            </div>
            <div style="color: black;">
            `+this.utilService.translate.instant('actives')+`: ${active}<br>
            `+this.utilService.translate.instant('not_actives')+`: ${notActive}
            </div>
            </div>
          `;
        }else if(this.name === 'odl'){
          const total = this.chartConfig.dataGroup.reduce((acc, data) => acc + data.active + data.notActive, 0);
          const active = this.chartConfig.dataGroup[tooltipModel.dataPoints[0].datasetIndex].active;
          const notActive = this.chartConfig.dataGroup[tooltipModel.dataPoints[0].datasetIndex].notActive;

        tooltipEl.style.zIndex = "1070";
          tooltipEl.innerHTML = `
          <div style='z-index:1070;background:red;'>
            <div style="font-size: 22px; margin: 4px 0; color: black;z-index:1070;backroung:red;">
              Totale: ${total}
            </div>
            <div style="color: black;">
            `+this.utilService.translate.instant('actives')+`: ${active}<br>
            `+this.utilService.translate.instant('not_actives')+`: ${notActive}
            </div>
            </div>
          `;
        }

      }

      // Calcola la posizione della tooltip
      const position = context.chart.canvas.getBoundingClientRect();
      tooltipEl.style.opacity = '1';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
      tooltipEl.style.pointerEvents = 'none'; // Permette all'utente di interagire con il documento al di sotto della tooltip
    };

    // Restituisce la funzione di custom tooltip a Chart.js
    return customTooltip;
  }

  ngOnInit() {

    if (this.chartConfig.chartColors.length <= 0) {
      this.chartConfig.chartColors = this.utilService.chartColors;
      this.registerCustomTooltip();
    }
    this.registerCustomTooltip();
    this.load();
  }

  async load() {

    this.chartData = {
      datasets: this.chartConfig.dataGroup.map((dataGroupItem, index) => {
        const borderWidth = index === this.chartConfig.dataGroup.length - 1 ? 2 : 8; // Esempio: l'ultimo ha borderWidth di 2, gli altri di 8

        return {
          label: dataGroupItem.label,
          data: dataGroupItem.values,
          backgroundColor: dataGroupItem.color,
          hoverBackgroundColor: dataGroupItem.color,
          hoverBorderColor: '#fff',
          borderRadius: this.chartConfig.borderRadius,
          borderColor: 'white',
          borderWidth: borderWidth, // Usa la variabile sopra definita
          datalabels: {
            formatter: (val) => {
              return val + this.chartConfig.datalabelUnit;
            },
            anchor: 'center',
            backgroundColor: null,
            borderWidth: this.chartConfig.datalabelsBorderWidth,
            color: this.chartConfig.datalabelsColor,
            borderColor: this.chartConfig.datalabelsBorderColor,
            font: function(context) {
              var width = context.chart.width;
              var height = context.chart.height;
              var size = Math.round(height / 16);
              return {
                size: size,
              };
            }
          }
        };
      })
    };
    this.chartData.labels = this.chartConfig.labels;
    this.chartOptions.cutout = this.cutout;

  }

  ngAfterView(){

  }

  ngOnChanges(simpleChanges: SimpleChange) {

  }

  ngOnDestroy() {

  }

}
