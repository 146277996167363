import { Component, Input, OnInit, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './app-snackbar.component.html',
  styleUrls: ['./app-snackbar.component.scss']
})
export class AppSnackbarComponent implements OnInit {

  constructor(    
    @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

  ngOnInit(): void {
    this.load();
  }

  async load() {
  }

}
