<div class="row h-100 m-0 p-0">
    <div class="main-container col-6 d-flex flex-column justify-content-center">
        <div class="title-row row my-4 mx-0 p-0">
            <div class="col-12">
                <div class="title my-2">
                    {{appConfigService.appSettings.appname}}
                </div>
                <div *ngIf="router.url.includes('/login')" class="subtitle mb-3 mt-0">
                    {{ 'login_your_account' | translate }}
                </div>
                <div *ngIf="!router.url.includes('/login') && !router.url.includes('/supplier-registration') && !router.url.includes('/affiliate-registration')" class="subtitle mb-3 mt-0">
                    {{ 'set_new_password' | translate }}
                </div>
                <div *ngIf="router.url.includes('/supplier-registration') || router.url.includes('/affiliate-registration')" class="subtitle mb-3 mt-0">
                    {{ 'new_provider_account' | translate }}
                </div>
                <ng-container *ngIf="router.url.includes('/login')">
                    <div *ngIf="!passwordRecovery" class="hint">
                        {{ 'login_hint' | translate }}
                    </div>
                    <div *ngIf="passwordRecovery" class="hint">
                        {{ 'password_recovery_hint' | translate }}
                    </div>
                </ng-container>
                <div *ngIf="!router.url.includes('/login') && !router.url.includes('/supplier-registration') && !router.url.includes('/affiliate-registration')" class="hint">
                    {{ 'insert_confirm_password' | translate }}
                </div>
                <div *ngIf="router.url.includes('/supplier-registration') || router.url.includes('/affiliate-registration')" class="hint">
                    {{ 'compile_required_fields' | translate }}
                </div>
            </div>
        </div>
        <form #form="ngForm" (ngSubmit)="submit()">
            <ng-container *ngIf="router.url.includes('/login')">
                <ng-container *ngIf="!passwordRecovery">
                    <div class="row m-0 p-0">
                        <div class="col-12">
                            <app-input #formField [placeholder]="'insert_username' | translate"  [label]="'Username'" [required]="true" [type]="'text'" [minLength]="'5'" [(property)]="username" (controlValidity)="checkFormValidation()"></app-input>
                        </div>
                    </div>
                    <div class="row m-0 p-0">
                        <div class="col-12">
                            <app-input #formField  [placeholder]="'insert_password' | translate" [label]="'Password'" [required]="true" [disablePasswordControl]="true" [type]="'password'" [(property)]="password" (controlValidity)="checkFormValidation()"></app-input>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="passwordRecovery">
                    <div class="row m-0 p-0">
                        <div class="col-12">
                            <app-input #formField  [placeholder]="'insert_email' | translate"  [label]="'Email'" [required]="true" [type]="'email'" [(property)]="email" (controlValidity)="checkFormValidation()"></app-input>
                        </div>
                    </div>
                </ng-container>

                <div class="row mb-2 mt-3 mx-0 p-0">
                    <div class="col-12 d-flex justify-content-center">
                        <span (click)="togglePasswordRecovery()" class="app-link">{{passwordRecovery ? ('back_login' | translate ) : ('forgot_password' | translate )}}</span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!router.url.includes('/login')">
                <div *ngIf="!router.url.includes('/supplier-registration') && !router.url.includes('/affiliate-registration')" class="row m-0 p-0">
                    <div class="col-12">
                        <app-input #formField [placeholder]="'insert_email' | translate"  [label]="'Email'" [required]="true" [type]="'email'" [(property)]="email" (controlValidity)="checkFormValidation()"></app-input>
                    </div>
                </div>
                <div *ngIf="router.url.includes('/supplier-registration') || router.url.includes('/affiliate-registration')" class="row m-0 p-0">
                    <div class="col-12">
                        <app-input #formField [placeholder]="'Username'"  [label]="'Username'" [required]="true" [type]="'text'" [minLength]="'5'" [(property)]="username" (controlValidity)="checkFormValidation()"></app-input>
                    </div>
                </div>
                <div *ngIf="router.url.includes('/supplier-registration') || router.url.includes('/affiliate-registration')" class="row m-0 p-0">
                    <div class="col-6">
                        <app-input #formField [placeholder]="'name' | translate"  [label]="'name' | translate" [required]="true" [type]="'text'" [(property)]="name" (controlValidity)="checkFormValidation()"></app-input>
                    </div>
                    <div class="col-6">
                        <app-input #formField [placeholder]="'surname' | translate"  [label]="'surname' | translate" [required]="true" [type]="'text'" [(property)]="surname" (controlValidity)="checkFormValidation()"></app-input>
                    </div>
                </div>
                <div class="row m-0 p-0">
                    <div class="col-12">
                        <app-input #formField  [placeholder]="'insert_password' | translate" [label]="'Password'" [required]="true" [type]="'password'" [(property)]="password" (controlValidity)="checkFormValidation()"></app-input>
                    </div>
                </div>

                <div class="row m-0 p-0">
                    <div class="col-12">
                        <app-input #formField  [placeholder]="'insert_password_confirm' | translate" [label]="'password_confirm' | translate" [required]="true" [type]="'password'" [(property)]="confirmPassword" (controlValidity)="checkFormValidation()"></app-input>
                    </div>
                </div>
            </ng-container>

            <div class="row my-3 mx-0 p-0">
                <div class="col-12 d-flex justify-content-center">
                    <button *ngIf="router.url.includes('/login')" class="app-button fill" [type]="'submit'" mat-raised-button [disabled]="!formValid">
                        {{passwordRecovery ? ('password_recovery' | translate ) : 'Login'}}
                    </button>
                    <button *ngIf="!router.url.includes('/login')" class="app-button fill" [type]="'submit'" mat-raised-button [disabled]="!formValid">
                        {{'confirm' | translate}}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="image-container col-6">
        <img [src]="appConfigService.appSettings?.logoSmall ? appConfigService?.appSettings?.logoSmall :  '../../../assets/logo.png'" alt="app logo">
    </div>
</div>

