<span mat-dialog-title class="d-flex align-items-start">
  {{data.title}}
  <mat-icon mat-dialog-close class="ms-auto">close</mat-icon>
</span>
<mat-dialog-content class="mat-typography" [innerHTML]="data.message">
  <!-- {{data.message}} -->
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
  <button *ngIf="data.showCancelButton" class="app-button stroked" mat-stroked-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button class="app-button" mat-raised-button [mat-dialog-close]="true">{{data.confirmButtonText ? data.confirmButtonText : 'confirm' | translate}}</button>
</mat-dialog-actions>
