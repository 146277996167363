<div class="row h-100 m-0 p-0">
    <div class="main-container col-6 d-flex flex-column justify-content-center">
        <div class="title-row row my-4 mx-0 p-0">
            <div class="col-12">
                <div class="title my-2">
                    {{appConfigService.appSettings.appname}}
                </div>
                <ng-container *ngIf="!message">
                    <div *ngIf="router.url.includes('/revision-confirm')" class="subtitle d-flex mb-3 mt-0">
                        {{ 'inspection_confirm' | translate }}
                        <mat-spinner class="load-spinner ms-2" strokeWidth="10"></mat-spinner>
                    </div>
                    <!-- <div *ngIf="router.url.includes('/revision-confirm')" class="hint">
                        {{ 'insert_confirm_password' | translate }}
                    </div> -->
                </ng-container>
                <ng-container *ngIf="message">
                    <div class="subtitle d-flex mb-3 mt-0">
                        {{message | translate}}
                        <mat-icon *ngIf="success" class="material-icons success ms-2">check_circle</mat-icon>
                        <mat-icon *ngIf="fail" class="material-icons error ms-2">cancel</mat-icon>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="image-container col-6">
        <img [src]="appConfigService.appSettings?.logoSmall ? appConfigService?.appSettings?.logoSmall :  '../../../assets/logo.png'" alt="app logo">
    </div>
</div>

