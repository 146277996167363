<span mat-dialog-title class="d-flex align-items-start">
  {{data.title}}
  <mat-icon mat-dialog-close class="ms-auto">close</mat-icon>
</span>
<mat-dialog-content class="mat-typography">
  <ngx-file-drop accept=".pdf" (onFileDrop)="droppedFile($event, data.title)" [multiple]="false">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="row justify-content-center m-0 p-0">
        <mat-icon class="material-icons-outlined p-0">cloud_upload</mat-icon>
        <div class="text-container justify-content-center row m-0 p-0">
          <span class="text me-1 ms-0 p-0">Trascina il documento nel riquadro o carica cliccando</span>
          <span class="link m-0 p-0" (click)="openFileSelector()">qui</span>
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>

  <div *ngIf="attachmentList?.length > 0" class="row mt-3 mb-0 mx-0 p-0">
    <div class="title col-12 m-0 p-0">
      File
    </div>
    <div *ngFor="let attachment of attachmentList" class="row align-items-center m-0 p-0">
      <span class="file-name m-0 p-0">{{attachment?.fileName}}</span>
      <span class="file-size">{{attachment?.size}}</span>
      <mat-icon class="remove-file material-icons-outlined p-0" (click)="removeAttachment(attachment)">close</mat-icon>
    </div>
    <div class="col-6 m-0 p-0">
      <app-input #formField [label]="'emission_date' | translate" [placeholder]="'emission_date' | translate" [required]="true" [type]="'date'" [maxDate]="maxDate" [(property)]="dataEmissione" [maxLength]="'100'" (controlValidity)="checkFormValidation()"></app-input>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
  <button *ngIf="data.showCancelButton" class="app-button stroked" mat-stroked-button mat-dialog-close>{{ 'cancel' | translate }}</button>
  <button class="app-button" (click)="confirmUpload()" mat-raised-button [disabled]="!attachmentList || attachmentList?.length <= 0 || !formValid">
    {{data.confirmButtonText ? data.confirmButtonText : 'confirm' | translate}}
  </button>
</mat-dialog-actions>