import { ChangeDetectorRef, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/DialogData';
import { UtilService } from 'src/app/services/util.service';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-upload-file-dialog',
  templateUrl: './upload-file-dialog.component.html',
  styleUrls: ['./upload-file-dialog.component.scss']
})
export class UploadFileDialogComponent {
  @ViewChildren('formField')
  formFields!: QueryList<any>;
  formValid = false;
  dataEmissione: any;
  attachmentList;
  maxDate = new Date().toISOString();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UploadFileDialogComponent>,
    private utilService: UtilService,
    private changeDetector: ChangeDetectorRef,
    ){
    dialogRef.disableClose = true;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    this.formFields?.changes.subscribe( ev => {
      this.checkFormValidation();
    })
  }

  checkFormValidation() {
    this.formValid = this.utilService.validateForm(this.formFields);
  }

  async droppedFile(file: NgxFileDropEntry[], document: string) {
    this.utilService.showSpinner();
    this.attachmentList = await this.utilService.droppedFile(file, document);
    this.utilService.hideSpinner();
  }

  removeAttachment(attachment) {
    const index = this.attachmentList.indexOf(attachment);
    if (index >= 0) {
      this.attachmentList.splice(index, 1);
    }
  }

  confirmUpload() {
    this.attachmentList[0].dataEmissione = this.dataEmissione;
    this.dialogRef.close(this.attachmentList[0]);
  }
}
