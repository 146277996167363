export class DialogData {
    title: string;
    subtitle: string;
    message: string;
    confirmButtonText: string;
    showCancelButton: boolean;
    element?: any;
    trigger?: any;

    constructor() {
        this.title = '';
        this.subtitle = '';
        this.message = '';
        this.confirmButtonText = ''
        this.showCancelButton = true;
        this.element = null;
    }
}