import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/models/DialogData';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  private readonly triggerElementRef: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ){
    dialogRef.disableClose = true;
    this.triggerElementRef = data?.trigger;
  }

  ngOnInit() {
    if (this.triggerElementRef) {
      const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
      const position = { left: `${rect.left}px`, top: `${rect.bottom - 50}px` };
      this.dialogRef.updatePosition(position);
    }
  }
}
