export class AppSettings {
    appname?: string;
    logo?: string;
    logoSmall?: string;
    loginBackground?: string;
    favicon?: string;
    primaryColor?: string;
    textPrimaryColor?: string;
    hoverPrimaryColor?: string;
    hoverTextPrimaryColor?: string;
    headerColor?: string
    headerTextColor: string;
    sidebarColor?: string;
    sidebarTextColor?: string;
    sidebarItemSelectedColor?: string;
    sidebarItemTextSelectedColor?: string;
    sidebarItemHoverColor?: string;
    sidebarItemTextHoverColor?: string;
    google_api_key?: string;

    constructor() {
        this.google_api_key = '';
    }
}