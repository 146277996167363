<div class="row m-0 p-0">
    <div *ngIf="data?.icon" class="col icon-column">
        <mat-icon *ngIf="!data?.iconSymbol" class="material-icons-outlined">{{data?.icon}}</mat-icon>
        <mat-icon *ngIf="data?.iconSymbol" class="material-symbols-outlined">{{data?.icon}}</mat-icon>
    </div>
    <div class="col text-column d-flex flex-column justify-content-center">
        <div *ngIf="data?.title" class="title">{{data?.title}}</div>
        <div [innerHTML]="data?.message"></div>
    </div>
</div>

