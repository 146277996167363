import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './app-page-title.component.html',
  styleUrls: ['./app-page-title.component.scss']
})
export class AppPageTitleComponent {

  @Input() type = 'view';
  @Input() title = 'title';
  @Input() normalTitle = false;
  @Input() canCreate = false;
  @Input() canEdit = false;
  @Input() canSave = false;
  @Input() enableSave = true;
  @Input() onlyIcon = true;
  @Input() stroked = false;
  @Input() size = 'medium';
  @Input() showTitle = false;
  @Input() showBackTitle = false;
  @Input() showSearch = false;
  @Input() searchValue = '';
  @Input() noPadding = false;
  @Output() searchValueChange = new EventEmitter<any>();
  @Output() createEvent = new EventEmitter<any>();
  @Output() editEvent = new EventEmitter<any>();
  @Output() saveEvent = new EventEmitter<any>();
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() backEvent = new EventEmitter<any>();
  @Output() searchEvent = new EventEmitter<any>();

  onSaveClick(event: any) {
    if (this.enableSave) {
      this.saveEvent.emit(event);
    }
  }

  onCreateClick(event: any) {
    this.createEvent.emit(event);
  }

  onEditClick(event: any) {
    this.editEvent.emit(event);
  }

  onBackClick(event: any) {
    this.backEvent.emit(event);
  }

  onCancelClick(event: any) {
    this.cancelEvent.emit(event);
  }

  onSearchClick() {
    this.searchEvent.emit(this.searchValue);
  }

  onResetSearchClick() {
    this.searchValue = '';
    this.onSearchClick();
  }
}
