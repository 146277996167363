import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './app-select.component.html',
  styleUrls: ['./app-select.component.scss']
})
export class AppSelectComponent implements OnChanges {

  @Input() label = 'label';
  @Input() placeholder = 'placeholder';
  @Input() hintStart!: string;
  @Input() hintEnd!: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() showCount = false;
  @Input() type = 'string';
  @Input() property: any;
  @Input() propertyViewKey = '';
  @Input() compareKey = 'id';
  @Input() options: any[] = [];
  @Input() small = false;
  @Input() noFloat = false;
  @Input() translateViewProperty = false;
  @Output() propertyChange = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();
  @Output() controlValidity = new EventEmitter<any>();
  formControl = new FormControl('', []);
  validators: ValidatorFn[] = [];

  constructor() {
  }

  ngOnInit() {
    if (this.required) {
      this.validators.push(Validators.required);
    }
    this.formControl = new FormControl({value: '', disabled: this.disabled || this.readonly}, this.validators);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['readonly']) {
      this.formControl = new FormControl({value: this.property, disabled: this.disabled || this.readonly}, this.validators);
    }
  }

  changeModel(event: any) {
    this.propertyChange.emit(event);
    this.controlValidity.emit(this.formControl.errors ? false : true);
  }

  onSelectionChange(event: any) {
    this.selectionChange.emit(event);
  }

  compareFunction = (o1: any, o2: any) => {
    if (typeof o1 === 'string' && typeof o2 === 'string') {
      return o1 === o2;
    } else if (this.type === 'objectIds') {
      return o1 === o2;
    } else {
      if (o1 && o2) {
        return o1[this.compareKey] === o2[this.compareKey];
      }
    }
    return false;
  }

  getObject(id: number) {
    const found = this.options.find(o => o.id === id);
    if (found) {
      return found[this.propertyViewKey];
    }
    return null;
  }

}
